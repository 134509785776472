import {Component, inject, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

@Component({
    selector: 'app-contacts-group-number-contacts',
    standalone: false,
    templateUrl: 'contacts-group.number-contacts.component.html',
})
export class AppContactsGroupNumberContactsComponent {
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _contactsGroupSource = new BehaviorSubject<ContactsGroup>(undefined!);
    private _contactsGroup$ = this._contactsGroupSource.asObservable();

    get contactsGroup$(): Observable<ContactsGroup> {
        return this._contactsGroup$;
    }

    @Input()
    set linkContactsGroup(value: string) {
        this._contactsGroupFactory.getByLink$(value).pipe(take(1)).subscribe(contactsGroup => this._contactsGroupSource.next(contactsGroup));
    }
}
