import {inject, Injectable} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {BienDiagnosticFactory} from '@models/bien/diagnostics/diagnostic/bien-diagnostic.factory';
import {BienSurfaceFactory} from '@models/bien/surfaces/surface/bien-surface.factory';
import {BienLogementFactory} from '@models/bien/logements/logement/bien-logement.factory';
import {BienCommoditeFactory} from '@models/bien/commodites/commodite/bien-commodite.factory';
import {BienChargeFactory} from '@models/bien/charges/charge/bien-charge.factory';
import {NgBien} from '@legacy/app/managers/ressources';
import {
    BienPerformanceEnergetiqueFactory
} from '@models/bien/performance-energetique/bien-performance-energetique.factory';
import {IBienApi, IBienMetadataApi, IBienOptionApi} from '@models/bien/bien.interfaces';
import {CommuneVoieFactory} from '@models/communes/commune/voies/voie/commune-voie.factory';
import {BienCadastreFactory} from '@models/bien/cadastres/cadastre/bien-cadastre.factory';
import DateFormat from '@shared/date/date.format';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {Observable} from 'rxjs';
import {BienApiService} from '@models/bien/bien.api.service';

@Injectable({providedIn: 'root'})
export class BienFactory {
    private _bienApiService = inject(BienApiService);
    private _bienCadastreFactory = inject(BienCadastreFactory);
    private _bienChargeFactory = inject(BienChargeFactory);
    private _bienCommoditeFactory = inject(BienCommoditeFactory);
    private _bienDiagnosticFactory = inject(BienDiagnosticFactory);
    private _bienLogementFactory = inject(BienLogementFactory);
    private _bienPerformanceEnergetiqueFactory = inject(BienPerformanceEnergetiqueFactory);
    private _bienSurfaceFactory = inject(BienSurfaceFactory);
    private _communeFactory = inject(CommuneFactory);
    private _communeVoieFactory = inject(CommuneVoieFactory);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _quartierFactory = inject(QuartierFactory);

    create(bienApi: IBienApi): Bien {
        const bien: Bien = this.createVirgin();

        bien.ancienneReference = bienApi.ancienneReference;
        bien.dateArchive = bienApi.dateArchive;
        bien.descriptif = bienApi.descriptif;
        bien.descriptifAffichette = bienApi.descriptifAffichette;
        bien.descriptifJournalPapier = bienApi.descriptifJournalPapier;
        bien.descriptifMandat = bienApi.descriptifMandat;
        bien.descriptifSiteInternet = bienApi.descriptifSiteInternet;
        bien.notaireId = bienApi.notaireId;
        bien.referenceInterne = bienApi.referenceInterne;
        bien.responsableDossierId = bienApi.responsableDossierId;
        bien.superficie = bienApi.superficie;
        bien.titre = bienApi.titre;
        if (bienApi._embedded) {
            bien.assainissements = (bienApi._embedded.assainissements || []).filter(assainissement => assainissement)
                .map(assainissement => this._dictionaryItemFactory.create(assainissement));
            bien.cadastres = (bienApi._embedded.cadastres || []).filter(cadastre => cadastre)
                .map(cadastre => this._bienCadastreFactory.create(cadastre));
            bien.charges = (bienApi._embedded.charges || []).filter(charge => charge)
                .map(charge => this._bienChargeFactory.create(charge));
            bien.charpentes = (bienApi._embedded.charpentes || []).filter(charpente => charpente)
                .map(charpente => this._dictionaryItemFactory.create(charpente));
            bien.chauffages = (bienApi._embedded.chauffages || []).filter(chauffage => chauffage)
                .map(chauffage => this._dictionaryItemFactory.create(chauffage));
            bien.clotures = (bienApi._embedded.clotures || []).filter(cloture => cloture)
                .map(cloture => this._dictionaryItemFactory.create(cloture));
            bien.commodites = (bienApi._embedded.commodites || []).filter(commodite => commodite)
                .map(commodite => this._bienCommoditeFactory.create(commodite));
            bien.connexionsInternet = (bienApi._embedded.connexionsInternet || []).filter(connexionInternet => connexionInternet)
                .map(connexionInternet => this._dictionaryItemFactory.create(connexionInternet));
            bien.diagnosticsRealises = (bienApi._embedded.diagnostics || []).filter(diagnostic => diagnostic)
                .map(diagnostic => this._bienDiagnosticFactory.create(diagnostic));
            bien.huisseries = (bienApi._embedded.huisseries || []).filter(huisserie => huisserie)
                .map(huisserie => this._dictionaryItemFactory.create(huisserie));
            bien.isolations = (bienApi._embedded.isolations || []).filter(isolation => isolation)
                .map(isolation => this._dictionaryItemFactory.create(isolation));
            bien.logements = (bienApi._embedded.logements || []).filter(logement => logement)
                .map(logement => this._bienLogementFactory.create(logement));
            bien.materiauxConstruction = (bienApi._embedded.materiauxConstruction || []).filter(materiauConstruction => materiauConstruction)
                .map(materiauConstruction => this._dictionaryItemFactory.create(materiauConstruction));
            bien.materiauxCouverture = (bienApi._embedded.materiauxCouverture || []).filter(materiauCouverture => materiauCouverture)
                .map(materiauCouverture => this._dictionaryItemFactory.create(materiauCouverture));
            bien.surfaces = (bienApi._embedded.pieces || []).filter(piece => piece)
                .map(piece => this._bienSurfaceFactory.create(piece));
            bien.volets = (bienApi._embedded.volets || []).filter(volet => volet)
                .map(volet => this._dictionaryItemFactory.create(volet));
            bien.vues = (bienApi._embedded.vues || []).filter(vue => vue)
                .map(vue => this._dictionaryItemFactory.create(vue));

            if (bienApi._embedded.contactsGroup) {
                bien.contactsGroup = this._contactsGroupFactory.create(bienApi._embedded.contactsGroup);
            }

            if (bienApi._embedded.metadata) {
                if (bienApi._embedded.metadata.confidentielles) {
                    bien.commentsConfidentiel = bienApi._embedded.metadata.confidentielles.comments;
                    bien.numeroCle = bienApi._embedded.metadata.confidentielles.numeroCle;
                    bien.panneaux = bienApi._embedded.metadata.confidentielles.panneaux;
                }

                if (bienApi._embedded.metadata.confreres) {
                    bien.clesVisite = bienApi._embedded.metadata.confreres.clesVisite;
                    bien.commentsConfreres = bienApi._embedded.metadata.confreres.comments;
                }

                if (bienApi._embedded.metadata.couts) {
                    bien.commentsCharges = bienApi._embedded.metadata.couts.comments;
                }

                if (bienApi._embedded.metadata.loiAlur) {
                    bien.administrationSyndicLoiAlur = bienApi._embedded.metadata.loiAlur.administrationSyndic;
                    bien.commentsLoiAlur = bienApi._embedded.metadata.loiAlur.comments;
                    bien.coordonneesSyndicLoiAlur = bienApi._embedded.metadata.loiAlur.coordonneesSyndic;
                    bien.hasLoiAlur = bienApi._embedded.metadata.loiAlur.loiAlur;
                    bien.montantQuotePartLoiAlur = bienApi._embedded.metadata.loiAlur.montantQuotePart;
                    bien.montantTravauxLoiAlur = bienApi._embedded.metadata.loiAlur.montantTravaux;
                    bien.nombreCoproprietairesLoiAlur = bienApi._embedded.metadata.loiAlur.nombreCoproprietaires;
                    bien.nombreLotsLoiAlur = bienApi._embedded.metadata.loiAlur.nombreLots;
                    bien.planSauvegardeLoiAlur = bienApi._embedded.metadata.loiAlur.planSauvegarde;
                }

                if (bienApi._embedded.metadata.occupation) {
                    bien.chargesLocatives = bienApi._embedded.metadata.occupation.chargesActuelles;
                    bien.loyerActuel = bienApi._embedded.metadata.occupation.loyerActuel;

                    if (bienApi._embedded.metadata.occupation.dateDisponibilite) {
                        bien.dateDisponibilite = bienApi._embedded.metadata.occupation.dateDisponibilite;
                    }
                }
            }

            if (bienApi._embedded.nature) {
                bien.nature = this._dictionaryItemFactory.create(bienApi._embedded.nature);
            }

            if (bienApi._embedded.option) {
                bien.accesHandicape = bienApi._embedded.option.accesHandicape;
                bien.adresseReseau = bienApi._embedded.option.adresseReseau;
                bien.alarmeLocation = bienApi._embedded.option.alarmeLocation;
                bien.alarmePleinePropriete = bienApi._embedded.option.alarmePleinePropriete;
                bien.anneeConstruction = bienApi._embedded.option.anneeConstruction;
                bien.annexe = bienApi._embedded.option.annexe;
                bien.ascenseur = bienApi._embedded.option.ascenseur;
                bien.assainissementNonConforme = bienApi._embedded.option.assainissementNonConforme;
                bien.aucunAssainissement = bienApi._embedded.option.aucunAssainissement;
                bien.aucunChauffage = bienApi._embedded.option.aucunChauffage;
                bien.balcon = bienApi._embedded.option.balcon;
                bien.causeVente = bienApi._embedded.option.causeVente;
                bien.cave = bienApi._embedded.option.cave;
                bien.chambreRdc = bienApi._embedded.option.chambreRdc;
                bien.chauffageCollectif = bienApi._embedded.option.chauffageCollectif;
                bien.cheminee = bienApi._embedded.option.cheminee;
                bien.chemineeFonctionnelle = bienApi._embedded.option.chemineeFonctionnelle;
                bien.chemineeOrnement = bienApi._embedded.option.chemineeOrnement;
                bien.citerneGazLocation = bienApi._embedded.option.citerneGazLocation;
                bien.citerneGazPleinePropriete = bienApi._embedded.option.citerneGazPleinePropriete;
                bien.codePostal = bienApi._embedded.option.codePostal;
                bien.combles = bienApi._embedded.option.comblesAmenageables;
                bien.commentsAccessibiliteEtEnvironnement = bienApi._embedded.option.commentsAccessibiliteEtEnvironnement;
                bien.commentsAgencementExterieur = bienApi._embedded.option.commentsAgencementExterieur;
                bien.commentsAgencementInterieur = bienApi._embedded.option.commentsAgencementInterieur;
                bien.commentsBatiment = bienApi._embedded.option.commentsBatiment;
                bien.commentsConstruction = bienApi._embedded.option.commentsConstruction;
                bien.commentsCouts = bienApi._embedded.option.commentsCouts;
                bien.commentsRisques = bienApi._embedded.option.risquesComments;
                bien.commentsTerrain = bienApi._embedded.option.commentsTerrain;
                bien.commentsUrbanisme = bienApi._embedded.option.urbanismeComments;
                bien.commentsVue = bienApi._embedded.option.commentsVue;
                bien.complementAdresse1 = bienApi._embedded.option.complementAdresse1;
                bien.cour = bienApi._embedded.option.cour;
                bien.cuisineEquipee = bienApi._embedded.option.cuisineEquipee;
                bien.cuveFuelAerienne = bienApi._embedded.option.cuveFuelAerienne;
                bien.cuveFuelEnterree = bienApi._embedded.option.cuveFuelEnterree;
                bien.cuveInactiveDegazee = bienApi._embedded.option.cuveInactiveDegazee;
                bien.cuveInactiveNeutralizee = bienApi._embedded.option.cuveInactiveNeutralizee;
                bien.declivite = bienApi._embedded.option.declivite;
                bien.degreDeclivite = bienApi._embedded.option.degreDeclivite;
                bien.dernierEtage = bienApi._embedded.option.dernierEtage;
                bien.designation = bienApi._embedded.option.designation;
                bien.detecteurFumee = bienApi._embedded.option.detecteurFumee;
                bien.exceptionnel = bienApi._embedded.option.exceptionnel;
                bien.garage = bienApi._embedded.option.garage;
                bien.grenier = bienApi._embedded.option.grenier;
                bien.hauteurSousPlafond = bienApi._embedded.option.hauteurSousPlafond;
                bien.longueurFacade = bienApi._embedded.option.longueurFacade;
                bien.nombreBains = bienApi._embedded.option.nombreBains;
                bien.nombreChambres = bienApi._embedded.option.nombreChambres;
                bien.nombreCheminees = bienApi._embedded.option.nombreCheminees;
                bien.nombreDouches = bienApi._embedded.option.nombreDouches;
                bien.nombreEtages = bienApi._embedded.option.nombreEtages;
                bien.nombreEtagesBatiment = bienApi._embedded.option.nombreEtagesBatiment;
                bien.nombreLogements = bienApi._embedded.option.nombreLogements;
                bien.nombrePieces = bienApi._embedded.option.nombrePieces;
                bien.nombrePlacesParking = bienApi._embedded.option.nombrePlacesParking;
                bien.nombreSallesBains = bienApi._embedded.option.nombreSallesBains;
                bien.nombreSallesEau = bienApi._embedded.option.nombreSallesEau;
                bien.nombreWc = bienApi._embedded.option.nombreWc;
                bien.nomVoie = bienApi._embedded.option.nomVoie;
                bien.numeroEtage = bienApi._embedded.option.numeroEtage;
                bien.numeroVoie = bienApi._embedded.option.numeroVoie;
                bien.panneauxPhotovoltaiques = bienApi._embedded.option.panneauxPhotovoltaiques;
                bien.pieceEauRdc = bienApi._embedded.option.pieceEauRdc;
                bien.piscine = bienApi._embedded.option.piscine;
                bien.pointEauCampagne = bienApi._embedded.option.pointEauCampagne;
                bien.pointEauVille = bienApi._embedded.option.pointEauVille;
                bien.residenceService = bienApi._embedded.option.residenceService;
                bien.servitude = bienApi._embedded.option.servitude;
                bien.sousSol = bienApi._embedded.option.sousSol;
                bien.surfaceAnnexe = bienApi._embedded.option.surfaceAnnexe;
                bien.surfaceBalcon = bienApi._embedded.option.surfaceBalcon;
                bien.surfaceBatie = bienApi._embedded.option.surfaceBatie;
                bien.surfaceCadastrale = bienApi._embedded.option.surfaceCadastrale;
                bien.surfaceCarrez = bienApi._embedded.option.surfaceCarrez;
                bien.surfaceCave = bienApi._embedded.option.surfaceCave;
                bien.surfaceCour = bienApi._embedded.option.surfaceCour;
                bien.surfaceGarage = bienApi._embedded.option.surfaceGarage;
                bien.surfaceNonBatie = bienApi._embedded.option.surfaceNonBatie;
                bien.surfacePlancher = bienApi._embedded.option.surfacePlancher;
                bien.surfaceSejour = bienApi._embedded.option.surfaceSejour;
                bien.surfaceTerrasse = bienApi._embedded.option.surfaceTerrasse;
                bien.terrasse = bienApi._embedded.option.terrasse;
                bien.urlVisiteVirtuelle = bienApi._embedded.option.urlVisiteVirtuelle;
                bien.veranda = bienApi._embedded.option.veranda;
                bien.viabilisable = bienApi._embedded.option.viabilisable;
                bien.viabilisationAssainissement = bienApi._embedded.option.viabilisationAssainissement;
                bien.viabilisationAssainissementCollectifRaccorde = bienApi._embedded.option.viabilisationAssainissementCollectifRaccorde;
                bien.viabilisationComments = bienApi._embedded.option.viabilisationComments;
                bien.viabilisationEau = bienApi._embedded.option.viabilisationEau;
                bien.viabilisationElectricite = bienApi._embedded.option.viabilisationElectricite;
                bien.viabilisationFibre = bienApi._embedded.option.viabilisationFibre;
                bien.viabilisationGaz = bienApi._embedded.option.viabilisationGaz;
                bien.viabilisationTelecom = bienApi._embedded.option.viabilisationTelecom;
                bien.viabilisationVoirie = bienApi._embedded.option.viabilisationVoirie;
                bien.wcBroyeur = bienApi._embedded.option.wcBroyeur;
                bien.zoneInondable = bienApi._embedded.option.zoneInondable;
                bien.zonePLU = bienApi._embedded.option.urbanismeZonePLU;
                bien.zoneRisques = bienApi._embedded.option.risquesZone;

                if (bienApi._embedded.option._embedded) {
                    if (bienApi._embedded.option._embedded.commune) {
                        bien.commune = this._communeFactory.create(bienApi._embedded.option._embedded.commune);
                    }

                    if (bienApi._embedded.option._embedded.epoqueConstruction) {
                        bien.epoqueConstruction = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.epoqueConstruction);
                    }

                    if (bienApi._embedded.option._embedded.etat) {
                        bien.etat = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.etat);
                    }

                    if (bienApi._embedded.option._embedded.exposition) {
                        bien.exposition = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.exposition);
                    }

                    if (bienApi._embedded.option._embedded.mitoyennete) {
                        bien.mitoyennete = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.mitoyennete);
                    }

                    if (bienApi._embedded.option._embedded.occupation) {
                        bien.occupation = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.occupation);
                    }

                    if (bienApi._embedded.option._embedded.plainPied) {
                        bien.plainPied = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.plainPied);
                    }

                    if (bienApi._embedded.option._embedded.quartier) {
                        bien.quartier = this._quartierFactory.create(bienApi._embedded.option._embedded.quartier);
                    }

                    if (bienApi._embedded.option._embedded.residence) {
                        bien.residence = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.residence);
                    }

                    if (bienApi._embedded.option._embedded.usage) {
                        bien.usage = this._dictionaryItemFactory.create(bienApi._embedded.option._embedded.usage);
                    }

                    if (bienApi._embedded.option._embedded.voie) {
                        bien.voie = this._communeVoieFactory.create(bienApi._embedded.option._embedded.voie);
                    }
                }
            }

            if (bienApi._embedded.performanceEnergetique) {
                bien.performanceEnergetique = this._bienPerformanceEnergetiqueFactory.create(bienApi._embedded.performanceEnergetique);
            }
        }

        if (bienApi._links) {
            if (bienApi._links.notaire) {
                bien.linkNotaire = bienApi._links.notaire.href;
            }

            if (bienApi._links.photos) {
                bien.linkPhotos = bienApi._links.photos.href;
            }

            if (bienApi._links.premierePhoto) {
                bien.linkPremierePhoto = bienApi._links.premierePhoto.href;
            }

            if (bienApi._links.privatePhotos) {
                bien.linkPrivatePhotos = bienApi._links.privatePhotos.href;
            }

            if (bienApi._links.privatePremierePhoto) {
                bien.linkPrivatePremierePhoto = bienApi._links.privatePremierePhoto.href;
            }

            if (bienApi._links.protectedPhotos) {
                bien.linkProtectedPhotos = bienApi._links.protectedPhotos.href;
            }

            if (bienApi._links.protectedPremierePhoto) {
                bien.linkProtectedPremierePhoto = bienApi._links.protectedPremierePhoto.href;
            }

            if (bienApi._links.responsableDossier) {
                bien.linkResponsableDossier = bienApi._links.responsableDossier.href;
            }
        }

        return bien;
    }

    createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi: IDossierBienDiffusionEtatApi): Bien {
        const bien = this.createVirgin();

        bien.contactsGroup = this._contactsGroupFactory.createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi);
        bien.linkPremierePhoto = dossierBienDiffusionEtatApi.premierePhoto;
        bien.nature = this._dictionaryItemService.getByCode(Dictionary.names.NATURES, dossierBienDiffusionEtatApi.codeNature);

        return bien;
    }

    createVirgin(): Bien {
        return new Bien();
    }

    forApi(bien: Bien): IBienApi {
        const bienApi = {
            ancienneReference: bien.ancienneReference,
            charpentes: (bien.charpentes || []).map(charpente => charpente.id),
            clotures: (bien.clotures || []).map(cloture => cloture.id),
            connexionsInternet: (bien.connexionsInternet || []).map(connexionInternet => connexionInternet.id),
            contactsGroupId: bien.contactsGroup?.id,
            descriptif: bien.descriptif,
            descriptifAffichette: bien.descriptifAffichette,
            descriptifJournalPapier: bien.descriptifJournalPapier,
            descriptifMandat: bien.descriptifMandat,
            descriptifSiteInternet: bien.descriptifSiteInternet,
            huisseries: (bien.huisseries || []).map(huisserie => huisserie.id),
            isolations: (bien.isolations || []).map(isolation => isolation.id),
            materiauxConstruction: (bien.materiauxConstruction || []).map(materiauConstruction => materiauConstruction.id),
            materiauxCouverture: (bien.materiauxCouverture || []).map(materiauCouverture => materiauCouverture.id),
            metadata: this.forApiMetadata(bien),
            natureId: bien.nature?.id,
            notaireId: bien.notaireId,
            option: this.forApiOption(bien),
            performanceEnergetique: this._bienPerformanceEnergetiqueFactory.forApi(bien.performanceEnergetique),
            referenceInterne: bien.referenceInterne,
            responsableDossierId: bien.responsableDossierId,
            superficie: bien.superficie,
            titre: bien.titre,
            volets: (bien.volets || []).map(volet => volet.id),
            vues: (bien.vues || []).map(vue => vue.id),
        } as IBienApi;

        if (!bien.aucunAssainissement) {
            bienApi.assainissements = (bien.assainissements || []).map(assainissement => assainissement.id);
        }

        if (!bien.aucunChauffage) {
            bienApi.chauffages = (bien.chauffages || []).map(chauffage => chauffage.id);
        }

        return bienApi;
    }

    forApiMetadata(bien: Bien): IBienMetadataApi {
        return {
            confidentielles: {comments: bien.commentsConfidentiel, numeroCle: bien.numeroCle, panneaux: bien.panneaux},
            confreres: {clesVisite: bien.clesVisite, comments: bien.commentsConfreres},
            couts: {comments: bien.commentsCharges},
            loiAlur: {
                administrationSyndic: bien.administrationSyndicLoiAlur,
                comments: bien.commentsLoiAlur,
                coordonneesSyndic: bien.coordonneesSyndicLoiAlur,
                loiAlur: bien.hasLoiAlur,
                montantQuotePart: +bien.montantQuotePartLoiAlur,
                montantTravaux: +bien.montantTravauxLoiAlur,
                nombreCoproprietaires: +bien.nombreCoproprietairesLoiAlur,
                nombreLots: +bien.nombreLotsLoiAlur,
                planSauvegarde: bien.planSauvegardeLoiAlur,
            },
            occupation: {
                chargesActuelles: +bien.chargesLocatives,
                dateDisponibilite: DateFormat.toAPI(bien.dateDisponibilite),
                loyerActuel: +bien.loyerActuel,
            },
        } as IBienMetadataApi;
    }

    forApiOption(bien: Bien): IBienOptionApi {
        const bienOptionApi = {
            accesHandicape: bien.accesHandicape,
            adresseReseau: bien.adresseReseau,
            alarmeLocation: bien.alarmeLocation,
            alarmePleinePropriete: bien.alarmePleinePropriete,
            anneeConstruction: bien.anneeConstruction,
            annexe: bien.annexe,
            ascenseur: bien.ascenseur,
            aucunAssainissement: bien.aucunAssainissement,
            aucunChauffage: bien.aucunChauffage,
            balcon: bien.balcon,
            causeVente: bien.causeVente,
            cave: bien.cave,
            chambreRdc: bien.chambreRdc,
            chauffageCollectif: bien.chauffageCollectif,
            cheminee: bien.cheminee,
            chemineeFonctionnelle: bien.chemineeFonctionnelle,
            chemineeOrnement: bien.chemineeOrnement,
            citerneGazLocation: bien.citerneGazLocation,
            citerneGazPleinePropriete: bien.citerneGazPleinePropriete,
            codePostal: bien.codePostal,
            comblesAmenageables: bien.combles,
            commentsAccessibiliteEtEnvironnement: bien.commentsAccessibiliteEtEnvironnement,
            commentsAgencementExterieur: bien.commentsAgencementExterieur,
            commentsAgencementInterieur: bien.commentsAgencementInterieur,
            commentsBatiment: bien.commentsBatiment,
            commentsConstruction: bien.commentsConstruction,
            commentsCouts: bien.commentsCouts,
            commentsTerrain: bien.commentsTerrain,
            commentsVue: bien.commentsVue,
            communeId: bien.commune?.id,
            complementAdresse1: bien.complementAdresse1,
            cour: bien.cour,
            cuisineEquipee: bien.cuisineEquipee,
            cuveFuelAerienne: bien.cuveFuelAerienne,
            cuveFuelEnterree: bien.cuveFuelEnterree,
            cuveInactiveDegazee: bien.cuveInactiveDegazee,
            cuveInactiveNeutralizee: bien.cuveInactiveNeutralizee,
            declivite: bien.declivite,
            degreDeclivite: bien.degreDeclivite,
            dernierEtage: bien.dernierEtage,
            designation: bien.designation,
            detecteurFumee: bien.detecteurFumee,
            epoqueConstructionId: bien.epoqueConstruction?.id,
            etatId: bien.etat?.id,
            exceptionnel: bien.exceptionnel,
            expositionId: bien.exposition?.id,
            garage: bien.garage,
            grenier: bien.grenier,
            hauteurSousPlafond: bien.hauteurSousPlafond,
            longueurFacade: bien.longueurFacade,
            mitoyenneteId: bien.mitoyennete?.id,
            nombreBains: bien.nombreBains,
            nombreChambres: bien.nombreChambres,
            nombreCheminees: bien.nombreCheminees,
            nombreDouches: bien.nombreDouches,
            nombreEtages: bien.nombreEtages,
            nombreEtagesBatiment: bien.nombreEtagesBatiment,
            nombreLogements: bien.nombreLogements,
            nombrePieces: bien.nombrePieces,
            nombrePlacesParking: bien.nombrePlacesParking,
            nombreSallesBains: bien.nombreSallesBains,
            nombreSallesEau: bien.nombreSallesEau,
            nombreWc: bien.nombreWc,
            nomVoie: bien.nomVoie,
            numeroEtage: bien.numeroEtage,
            numeroVoie: bien.numeroVoie || null,
            occupationId: bien.occupation?.id,
            panneauxPhotovoltaiques: bien.panneauxPhotovoltaiques,
            pieceEauRdc: bien.pieceEauRdc,
            piscine: bien.piscine,
            plainPiedId: bien.plainPied?.id,
            pointEauCampagne: bien.pointEauCampagne,
            pointEauVille: bien.pointEauVille,
            quartierId: bien.quartier?.id,
            residenceId: bien.residence?.id,
            residenceService: bien.residenceService,
            risquesComments: bien.commentsRisques,
            risquesZone: bien.zoneRisques,
            servitude: bien.servitude,
            sousSol: bien.sousSol,
            surfaceAnnexe: bien.surfaceAnnexe,
            surfaceBalcon: bien.surfaceBalcon,
            surfaceBatie: bien.surfaceBatie,
            surfaceCadastrale: bien.surfaceCadastrale,
            surfaceCarrez: bien.surfaceCarrez,
            surfaceCave: bien.surfaceCave,
            surfaceCour: bien.surfaceCour,
            surfaceGarage: bien.surfaceGarage,
            surfaceNonBatie: bien.surfaceNonBatie,
            surfacePlancher: bien.surfacePlancher,
            surfaceSejour: bien.surfaceSejour,
            surfaceTerrasse: bien.surfaceTerrasse,
            terrasse: bien.terrasse,
            urbanismeZonePLU: bien.zonePLU,
            urbanismeComments: bien.commentsUrbanisme,
            urlVisiteVirtuelle: bien.urlVisiteVirtuelle,
            usageId: bien.usage?.id,
            veranda: bien.veranda,
            viabilisable: bien.viabilisable,
            viabilisationComments: bien.viabilisationComments,
            viabilisationEau: bien.viabilisationEau,
            viabilisationElectricite: bien.viabilisationElectricite,
            viabilisationFibre: bien.viabilisationFibre,
            viabilisationGaz: bien.viabilisationGaz,
            viabilisationTelecom: bien.viabilisationTelecom,
            viabilisationVoirie: bien.viabilisationVoirie,
            wcBroyeur: bien.wcBroyeur,
            zoneInondable: bien.zoneInondable,
        } as IBienOptionApi;

        if (!bien.aucunAssainissement) {
            bienOptionApi.assainissementNonConforme = bien.assainissementNonConforme;
            bienOptionApi.viabilisationAssainissement = bien.viabilisationAssainissement;
            bienOptionApi.viabilisationAssainissementCollectifRaccorde = bien.viabilisationAssainissementCollectifRaccorde;
        }

        if (bien.commune?.hasVoies) {
            bienOptionApi.voieId = bien.voie?.id;
        }

        return bienOptionApi;
    }

    getDescriptif$(dossierBienId: number): Observable<string> {
        return this._bienApiService.getDescriptif$({bienId: dossierBienId.toString()});
    }

    getTitre$(dossierBienId: number): Observable<string> {
        return this._bienApiService.getTitre$({bienId: dossierBienId.toString()});
    }

    ngCreate(ngBien: NgBien): Bien {
        const bien = this.createVirgin();

        bien.ancienneReference = ngBien.ancienneReference;
        bien.assainissements = (ngBien.assainissements || []).filter(assainissement => assainissement)
            .map(assainissement => this._dictionaryItemFactory.ngCreate(assainissement));
        bien.cadastres = (ngBien.cadastres || []).filter(cadastre => cadastre)
            .map(cadastre => this._bienCadastreFactory.ngCreate(cadastre));
        bien.charges = (ngBien.charges || []).filter(charge => charge)
            .map(charge => this._bienChargeFactory.ngCreate(charge));
        bien.charpentes = (ngBien.charpentes || []).filter(charpente => charpente)
            .map(charpente => this._dictionaryItemFactory.ngCreate(charpente));
        bien.chauffages = (ngBien.chauffages || []).filter(chauffage => chauffage)
            .map(chauffage => this._dictionaryItemFactory.ngCreate(chauffage));
        bien.clotures = (ngBien.clotures || []).filter(cloture => cloture)
            .map(cloture => this._dictionaryItemFactory.ngCreate(cloture));
        bien.commodites = (ngBien.commodites || []).filter(commodite => commodite)
            .map(commodite => this._bienCommoditeFactory.ngCreate(commodite));
        bien.connexionsInternet = (ngBien.connexionsInternet || []).filter(connexionInternet => connexionInternet)
            .map(connexionInternet => this._dictionaryItemFactory.ngCreate(connexionInternet));
        bien.dateArchive = DateFormat.toJSON(ngBien.dateArchive);
        bien.descriptif = ngBien.descriptif;
        bien.descriptifAffichette = ngBien.descriptifAffichette;
        bien.descriptifJournalPapier = ngBien.descriptifJournalPapier;
        bien.descriptifMandat = ngBien.descriptifMandat;
        bien.descriptifSiteInternet = ngBien.descriptifSiteInternet;
        bien.diagnosticsRealises = (ngBien.diagnostics || []).filter(diagnostic => diagnostic)
            .map(diagnostic => this._bienDiagnosticFactory.ngCreate(diagnostic));
        bien.huisseries = (ngBien.huisseries || []).filter(huisserie => huisserie)
            .map(huisserie => this._dictionaryItemFactory.ngCreate(huisserie));
        bien.isolations = (ngBien.isolations || []).filter(isolation => isolation)
            .map(isolation => this._dictionaryItemFactory.ngCreate(isolation));
        bien.logements = (ngBien.logements || []).filter(logement => logement)
            .map(logement => this._bienLogementFactory.ngCreate(logement));
        bien.materiauxConstruction = (ngBien.materiauxConstruction || []).filter(materiauConstruction => materiauConstruction)
            .map(materiauConstruction => this._dictionaryItemFactory.ngCreate(materiauConstruction));
        bien.materiauxCouverture = (ngBien.materiauxCouverture || []).filter(materiauCouverture => materiauCouverture)
            .map(materiauCouverture => this._dictionaryItemFactory.ngCreate(materiauCouverture));
        bien.notaireId = ngBien.notaireId;
        bien.referenceInterne = ngBien.referenceInterne;
        bien.responsableDossierId = ngBien.responsableDossier?.id;
        bien.superficie = parseFloat((ngBien.superficie ?? 0).toString().replace(',', '.'));
        bien.surfaces = (ngBien.pieces || []).filter(piece => piece)
            .map(piece => this._bienSurfaceFactory.ngCreate(piece));
        bien.titre = ngBien.titre;
        bien.volets = (ngBien.volets || []).filter(volet => volet)
            .map(volet => this._dictionaryItemFactory.ngCreate(volet));
        bien.vues = (ngBien.vues || []).filter(vue => vue)
            .map(vue => this._dictionaryItemFactory.ngCreate(vue));

        if (ngBien.contactsGroup) {
            bien.contactsGroup = this._contactsGroupFactory.ngCreate(ngBien.contactsGroup);
        }

        if (ngBien.metadata) {
            if (ngBien.metadata.confidentielles) {
                bien.commentsConfidentiel = ngBien.metadata.confidentielles.comments;
                bien.numeroCle = ngBien.metadata.confidentielles.numeroCle;
                bien.panneaux = ngBien.metadata.confidentielles.panneaux;
            }

            if (ngBien.metadata.confreres) {
                bien.clesVisite = ngBien.metadata.confreres.clesVisite;
                bien.commentsConfreres = ngBien.metadata.confreres.comments;
            }

            if (ngBien.metadata.couts) {
                bien.commentsCharges = ngBien.metadata.couts.comments;
            }

            if (ngBien.metadata.loiAlur) {
                bien.administrationSyndicLoiAlur = ngBien.metadata.loiAlur.administrationSyndic;
                bien.commentsLoiAlur = ngBien.metadata.loiAlur.comments;
                bien.coordonneesSyndicLoiAlur = ngBien.metadata.loiAlur.coordonneesSyndic;
                bien.hasLoiAlur = ngBien.metadata.loiAlur.loiAlur;
                bien.montantQuotePartLoiAlur = ngBien.metadata.loiAlur.montantQuotePart;
                bien.montantTravauxLoiAlur = ngBien.metadata.loiAlur.montantTravaux;
                bien.nombreCoproprietairesLoiAlur = ngBien.metadata.loiAlur.nombreCoproprietaires;
                bien.nombreLotsLoiAlur = ngBien.metadata.loiAlur.nombreLots;
                bien.planSauvegardeLoiAlur = ngBien.metadata.loiAlur.planSauvegarde;
            }

            if (ngBien.metadata.occupation) {
                bien.chargesLocatives = ngBien.metadata.occupation.chargesActuelles;
                bien.dateDisponibilite = DateFormat.toJSON(ngBien.metadata.occupation.dateDisponibilite);
                bien.loyerActuel = ngBien.metadata.occupation.loyerActuel;
            }
        }

        if (ngBien.nature) {
            bien.nature = this._dictionaryItemFactory.ngCreate(ngBien.nature);
        }

        if (ngBien.option) {
            bien.accesHandicape = ngBien.option.accesHandicape;
            bien.adresseReseau = ngBien.option.adresseReseau;
            bien.alarmeLocation = ngBien.option.alarmeLocation;
            bien.alarmePleinePropriete = ngBien.option.alarmePleinePropriete;
            bien.anneeConstruction = ngBien.option.anneeConstruction;
            bien.annexe = ngBien.option.annexe;
            bien.ascenseur = ngBien.option.ascenseur;
            bien.assainissementNonConforme = ngBien.option.assainissementNonConforme;
            bien.aucunAssainissement = ngBien.option.aucunAssainissement;
            bien.aucunChauffage = ngBien.option.aucunChauffage;
            bien.balcon = ngBien.option.balcon;
            bien.causeVente = ngBien.option.causeVente;
            bien.cave = ngBien.option.cave;
            bien.chambreRdc = ngBien.option.chambreRdc;
            bien.chauffageCollectif = ngBien.option.chauffageCollectif;
            bien.cheminee = ngBien.option.cheminee;
            bien.chemineeFonctionnelle = ngBien.option.chemineeFonctionnelle;
            bien.chemineeOrnement = ngBien.option.chemineeOrnement;
            bien.citerneGazLocation = ngBien.option.citerneGazLocation;
            bien.citerneGazPleinePropriete = ngBien.option.citerneGazPleinePropriete;
            bien.codePostal = ngBien.option.codePostal;
            bien.combles = ngBien.option.comblesAmenageables;
            bien.commentsAccessibiliteEtEnvironnement = ngBien.option.commentsAccessibiliteEtEnvironnement;
            bien.commentsAgencementExterieur = ngBien.option.commentsAgencementExterieur;
            bien.commentsAgencementInterieur = ngBien.option.commentsAgencementInterieur;
            bien.commentsBatiment = ngBien.option.commentsBatiment;
            bien.commentsConstruction = ngBien.option.commentsConstruction;
            bien.commentsCouts = ngBien.option.commentsCouts;
            bien.commentsRisques = ngBien.option.risquesComments;
            bien.commentsTerrain = ngBien.option.commentsTerrain;
            bien.commentsUrbanisme = ngBien.option.urbanismeComments;
            bien.commentsVue = ngBien.option.commentsVue;
            bien.complementAdresse1 = ngBien.option.complementAdresse1;
            bien.cour = ngBien.option.cour;
            bien.cuisineEquipee = ngBien.option.cuisineEquipee;
            bien.cuveFuelAerienne = ngBien.option.cuveFuelAerienne;
            bien.cuveFuelEnterree = ngBien.option.cuveFuelEnterree;
            bien.cuveInactiveDegazee = ngBien.option.cuveInactiveDegazee;
            bien.cuveInactiveNeutralizee = ngBien.option.cuveInactiveNeutralizee;
            bien.declivite = ngBien.option.declivite;
            bien.degreDeclivite = ngBien.option.degreDeclivite;
            bien.dernierEtage = ngBien.option.dernierEtage;
            bien.designation = ngBien.option.designation;
            bien.detecteurFumee = ngBien.option.detecteurFumee;
            bien.exceptionnel = ngBien.option.exceptionnel;
            bien.garage = ngBien.option.garage;
            bien.grenier = ngBien.option.grenier;
            bien.hauteurSousPlafond = parseFloat((ngBien.option.hauteurSousPlafond ?? 0).toString().replace(',', '.'));
            bien.longueurFacade = parseFloat((ngBien.option.longueurFacade ?? 0).toString().replace(',', '.'));
            bien.nombreBains = ngBien.option.nombreBains;
            bien.nombreChambres = ngBien.option.nombreChambres;
            bien.nombreCheminees = ngBien.option.nombreCheminees;
            bien.nombreDouches = ngBien.option.nombreDouches;
            bien.nombreEtages = ngBien.option.nombreEtages;
            bien.nombreEtagesBatiment = ngBien.option.nombreEtagesBatiment;
            bien.nombreLogements = ngBien.option.nombreLogements;
            bien.nombrePieces = ngBien.option.nombrePieces;
            bien.nombrePlacesParking = ngBien.option.nombrePlacesParking;
            bien.nombreSallesBains = ngBien.option.nombreSallesBains;
            bien.nombreSallesEau = ngBien.option.nombreSallesEau;
            bien.nombreWc = ngBien.option.nombreWc;
            bien.nomVoie = ngBien.option.nomVoie;
            bien.numeroEtage = ngBien.option.numeroEtage;
            bien.numeroVoie = ngBien.option.numeroVoie;
            bien.panneauxPhotovoltaiques = ngBien.option.panneauxPhotovoltaiques;
            bien.pieceEauRdc = ngBien.option.pieceEauRdc;
            bien.piscine = ngBien.option.piscine;
            bien.pointEauCampagne = ngBien.option.pointEauCampagne;
            bien.pointEauVille = ngBien.option.pointEauVille;
            bien.residenceService = ngBien.option.residenceService;
            bien.servitude = ngBien.option.servitude;
            bien.sousSol = ngBien.option.sousSol;
            bien.surfaceAnnexe = parseFloat((ngBien.option.surfaceAnnexe ?? 0).toString().replace(',', '.'));
            bien.surfaceBalcon = parseFloat((ngBien.option.surfaceBalcon ?? 0).toString().replace(',', '.'));
            bien.surfaceBatie = parseFloat((ngBien.option.surfaceBatie ?? 0).toString().replace(',', '.'));
            bien.surfaceCadastrale = parseFloat((ngBien.option.surfaceCadastrale ?? 0).toString().replace(',', '.'));
            bien.surfaceCarrez = parseFloat((ngBien.option.surfaceCarrez ?? 0).toString().replace(',', '.'));
            bien.surfaceCave = parseFloat((ngBien.option.surfaceCave ?? 0).toString().replace(',', '.'));
            bien.surfaceCour = parseFloat((ngBien.option.surfaceCour ?? 0).toString().replace(',', '.'));
            bien.surfaceGarage = parseFloat((ngBien.option.surfaceGarage ?? 0).toString().replace(',', '.'));
            bien.surfaceNonBatie = parseFloat((ngBien.option.surfaceNonBatie ?? 0).toString().replace(',', '.'));
            bien.surfacePlancher = parseFloat((ngBien.option.surfacePlancher ?? 0).toString().replace(',', '.'));
            bien.surfaceSejour = parseFloat((ngBien.option.surfaceSejour ?? 0).toString().replace(',', '.'));
            bien.surfaceTerrasse = parseFloat((ngBien.option.surfaceTerrasse ?? 0).toString().replace(',', '.'));
            bien.terrasse = ngBien.option.terrasse;
            bien.urlVisiteVirtuelle = ngBien.option.urlVisiteVirtuelle;
            bien.veranda = ngBien.option.veranda;
            bien.viabilisable = ngBien.option.viabilisable;
            bien.viabilisationAssainissement = ngBien.option.viabilisationAssainissement;
            bien.viabilisationAssainissementCollectifRaccorde = ngBien.option.viabilisationAssainissementCollectifRaccorde;
            bien.viabilisationComments = ngBien.option.viabilisationComments;
            bien.viabilisationEau = ngBien.option.viabilisationEau;
            bien.viabilisationElectricite = ngBien.option.viabilisationElectricite;
            bien.viabilisationFibre = ngBien.option.viabilisationFibre;
            bien.viabilisationGaz = ngBien.option.viabilisationGaz;
            bien.viabilisationTelecom = ngBien.option.viabilisationTelecom;
            bien.viabilisationVoirie = ngBien.option.viabilisationVoirie;
            bien.wcBroyeur = ngBien.option.wcBroyeur;
            bien.zoneInondable = ngBien.option.zoneInondable;
            bien.zonePLU = ngBien.option.urbanismeZonePLU;
            bien.zoneRisques = ngBien.option.risquesZone;

            if (ngBien.option.commune) {
                bien.commune = this._communeFactory.ngCreate(ngBien.option.commune);
            }

            if (ngBien.option.epoqueConstruction) {
                bien.epoqueConstruction = this._dictionaryItemFactory.ngCreate(ngBien.option.epoqueConstruction);
            }

            if (ngBien.option.etat) {
                bien.etat = this._dictionaryItemFactory.ngCreate(ngBien.option.etat);
            }

            if (ngBien.option.exposition) {
                bien.exposition = this._dictionaryItemFactory.ngCreate(ngBien.option.exposition);
            }

            if (ngBien.option.mitoyennete) {
                bien.mitoyennete = this._dictionaryItemFactory.ngCreate(ngBien.option.mitoyennete);
            }

            if (ngBien.option.occupation) {
                bien.occupation = this._dictionaryItemFactory.ngCreate(ngBien.option.occupation);
            }

            if (ngBien.option.plainPied) {
                bien.plainPied = this._dictionaryItemFactory.ngCreate(ngBien.option.plainPied);
            }

            if (ngBien.option.quartier) {
                bien.quartier = this._quartierFactory.ngCreate(ngBien.option.quartier);
            }

            if (ngBien.option.residence) {
                bien.residence = this._dictionaryItemFactory.ngCreate(ngBien.option.residence);
            }

            if (ngBien.option.usage) {
                bien.usage = this._dictionaryItemFactory.ngCreate(ngBien.option.usage);
            }

            if (ngBien.option.voie) {
                bien.voie = this._communeVoieFactory.ngCreate(ngBien.option.voie);
            }
        }

        if (ngBien.performanceEnergetique) {
            bien.performanceEnergetique = this._bienPerformanceEnergetiqueFactory.ngCreate(ngBien.performanceEnergetique);
        }

        if (ngBien._links) {
            if (ngBien._links.notaire) {
                bien.linkNotaire = ngBien._links.notaire.href;
            }

            if (ngBien._links.photos) {
                bien.linkPhotos = ngBien._links.photos.href;
            }

            if (ngBien._links.premierePhoto) {
                bien.linkPremierePhoto = ngBien._links.premierePhoto.href;
            }

            if (ngBien._links.privatePhotos) {
                bien.linkPrivatePhotos = ngBien._links.privatePhotos.href;
            }

            if (ngBien._links.privatePremierePhoto) {
                bien.linkPrivatePremierePhoto = ngBien._links.privatePremierePhoto.href;
            }

            if (ngBien._links.protectedPhotos) {
                bien.linkProtectedPhotos = ngBien._links.protectedPhotos.href;
            }

            if (ngBien._links.protectedPremierePhoto) {
                bien.linkProtectedPremierePhoto = ngBien._links.protectedPremierePhoto.href;
            }

            if (ngBien._links.responsableDossier) {
                bien.linkResponsableDossier = ngBien._links.responsableDossier.href;
            }
        }

        return bien;
    }
}
