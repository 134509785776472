import {Component, inject, Input, output, ViewChild} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import Vente from '@models/ventes/vente/vente.model';
import {CVentesService} from '@models/ventes/collection/ventes.collection.service';
import Contact from '@models/contacts/contact/contact.model';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';

@Component({
    exportAs: 'ventesSelect',
    selector: 'app-ventes-select',
    standalone: false,
    templateUrl: 'ventes.select.component.html',
})
export class AppVentesSelectComponent {
    static readonly initVentesSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'convivialName',
        loadingText: 'Chargement des biens en vente',
        name: 'ventesSelect',
        notFoundText: 'Aucun bien en vente trouvé',
        placeholder: 'À définir',
        // Placeholder: 'Rechercher un bien en vente par son nom, ses téléphones, son adresse email ou ses références',
    };
    readonly selected = output<Vente>();
    private _cVentesService = inject(CVentesService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppVentesSelectComponent.initVentesSelectOptions};
    private _vente!: Vente;
    private _list: Vente[] = [];

    get vente(): Vente {
        return this._vente;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;
    }

    get list(): (Vente | Contact)[] {
        return this._list;
    }

    @ViewChild('ventesSelectInput')
    set ventesSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppVentesSelectComponent.initVentesSelectOptions, ...value};
    }

    onSelect(data: unknown): void {
        this.selected.emit(data as Vente);
    }

    search(search: string): void {
        this._list = [];
        this._loading = true;
        this._cVentesService.search$(search)
            .pipe(map(cVentes => cVentes.results), take(1))
            .subscribe({next: list => this._list = list, complete: () => this._loading = false});
    }
}
