import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {
    IOffreachatActionsMainData, IOffreachatEditForms, IOffreachatEditOptions
} from '@features/offreachats/offreachats.interfaces';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Vente from '@models/ventes/vente/vente.model';
import {ModalService} from '@shared/modal/modal.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Router} from '@angular/router';
import {
    OffreachatEditActionsMainComponent
} from '@features/offreachats/item/actions/edit/offreachat-edit.actions-main.component';
import {ToasterService} from '@shared/toaster/toaster.service';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

@Component({
    selector: 'layout-offreachat-edit',
    standalone: false,
    templateUrl: 'layout.offreachat-edit.component.html',
})
export class AppLayoutOffreachatEditComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        DEMANDEUR: 'Le demandeur associé n\'est pas correctement saisi.',
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.',
        EDIT_DISABLED: 'L\'édition de votre offre d\'achat n\'est pas disponible.',
        TITLE: 'Données incomplètes',
        VENTE: 'Le bien en vente associé n\'est pas correctement saisi.',
    };
    private _callToActionService = inject(CallToActionService);
    private _modalService = inject(ModalService);
    private _offreachatService = inject(OffreachatService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _offreachatSource = new ReplaySubject<Offreachat>(1);
    private _offreachat$ = this._offreachatSource.asObservable();
    private _offreachatEditOptions!: IOffreachatEditOptions;
    private _offreachatsRoot!: string;
    private _demandeur!: Demandeur;
    private _formDemandeurSource = new ReplaySubject<void>(1);
    private _forms!: IOffreachatEditForms;
    private _formOffreSource = new ReplaySubject<void>(1);
    private readonly _onDestroy$ = new Subject<void>();
    private _vente!: Vente;

    get offreachat$(): Observable<Offreachat> {
        return this._offreachat$;
    }

    get offreachatEditOptions(): IOffreachatEditOptions {
        return this._offreachatEditOptions;
    }

    set demandeur(value: Demandeur) {
        this._demandeur = value;
    }

    set forms(value: IOffreachatEditForms) {
        this._forms = value;
    }

    set vente(value: Vente) {
        this._vente = value;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        this._offreachatsRoot = '/app/offres-achat';
        if (!this._router.url.startsWith('/app/offres-achat')) {
            this._offreachatsRoot = this._router.url.split('/offres-achat')[0] + '/offres-achat';
            fallbackPath = this._offreachatsRoot + '/portefeuille';
        }

        this._offreachatService.current$.pipe(filter(offreachat => !!offreachat), take(1)).subscribe(offreachat => {
            if (!offreachat.isEdit()) {
                this._toasterService.warning(AppLayoutOffreachatEditComponent.errorMessages.EDIT_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._offreachatService.current$.pipe(
            filter(offreachat => !!offreachat),
            tap(_ => this._offreachatEditOptions = {
                fixedDemandeur: this._router.url.includes(DossierTypesConst.DEMANDEUR),
                fixedVente: this._router.url.includes(DossierTypesConst.VENTE),
            }),
            tap(offreachat => {
                const data: IOffreachatActionsMainData = {
                    offreachat,
                    options: {
                        fromDossier: this._router.url.includes(DossierTypesConst.DEMANDEUR) || this._router.url.includes(DossierTypesConst.VENTE),
                    },
                };

                this._callToActionService.setDynamicComponentLoading({
                    component: OffreachatEditActionsMainComponent,
                    data,
                });
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(offreachat => this._offreachatSource.next(offreachat));
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === OffreachatEditActionsMainComponent.actions.ARCHIVE) {
                    return this._callToActionService.actionExec$(this.archiveCurrent$());
                } else if (callToActionClicked.action === OffreachatEditActionsMainComponent.actions.SAVE) {
                    return this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === OffreachatEditActionsMainComponent.actions.WRITE) {
                    return this._callToActionService.actionExec$(this.write$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    archiveCurrent$(): Observable<string> {
        return this._offreachatService.archiveCurrent$().pipe(
            switchMap(isArchived => this.offreachat$.pipe(
                take(1),
                map(offreachat => isArchived ? this._offreachatsRoot + '/' + offreachat.uuid + '/resume' : undefined!))
            ),
        );
    }

    save$(): Observable<string> {
        let isOffreachatNew = false;

        return this.offreachat$.pipe(
            take(1),
            tap(offreachat => isOffreachatNew = offreachat.isNew()),
            switchMap(_ => this.saveCurrent$()),
            map(offreachat => isOffreachatNew && offreachat ? this._offreachatsRoot + '/' + offreachat.uuid + '/edit' : undefined!),
        );
    }

    saveCurrent$(): Observable<Offreachat> {
        if (!this._forms.demandeur) {
            this.saveDemandeur();
        } else if (this._forms.demandeur && !this._forms.demandeur.submitted) {
            setTimeout(() => document.querySelector<HTMLButtonElement>('[form=demandeurForm]')!.click(), 1);
        }

        if (!this._forms.offre.submitted) {
            setTimeout(() => document.querySelector<HTMLButtonElement>('[form=offreForm]')!.click(), 1);
        }

        return combineLatest([this._formDemandeurSource.asObservable(), this._formOffreSource.asObservable()]).pipe(
            map(_ => {
                const errorComments: string[] = [];

                if (this._forms.demandeur && !this._forms.demandeur.valid) {
                    errorComments.push(AppLayoutOffreachatEditComponent.errorMessages.DEMANDEUR);
                }

                if (!this._vente) {
                    errorComments.push(AppLayoutOffreachatEditComponent.errorMessages.VENTE);
                }

                if (!this._forms.offre.valid) {
                    errorComments.push(AppLayoutOffreachatEditComponent.errorMessages.OTHER);
                }

                return errorComments;
            }),
            switchMap(errorComments => {
                if (errorComments.length > 0) {
                    return this._modalService.openInformation$({
                        comments: errorComments.join('<br>'),
                        title: AppLayoutOffreachatEditComponent.errorMessages.TITLE,
                        status: ModalService.status.WARNING,
                    }).pipe(map(_ => undefined!));
                }

                return this._offreachatService.saveCurrent$({demandeur: this._demandeur, vente: this._vente});
            }),
        );
    }

    saveDemandeur(): void {
        this._formDemandeurSource.next();
    }

    saveOffre(): void {
        this._formOffreSource.next();
    }

    write$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(offreachat => offreachat ? this._offreachatService.writeCurrent$() : of(undefined!)),
            map(offreachat => offreachat ? this._offreachatsRoot + '/' + offreachat.uuid + '/redaction' : undefined!),
        );
    }
}
