import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {Observable, Subject, switchMap} from 'rxjs';
import User from '@models/users/user/user.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import {NgForm} from '@angular/forms';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {UserSettingsFactory} from '@models/users/user/settings/user-settings.factory';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({
    selector: 'layout-configuration-dossiers',
    standalone: false,
    templateUrl: 'layout-configuration-dossiers.component.html',
})
export class AppLayoutConfigurationDossiersComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.<br/>Ils sont indiqués en rouge.',
        TITLE: 'Données incomplètes',
    };
    @ViewChild('dossiersForm') dossiersForm!: NgForm;
    private _callToActionService = inject(CallToActionService);
    private _modalService = inject(ModalService);
    private _userService = inject(UserService);
    private _userSettingsFactory = inject(UserSettingsFactory);
    private _defaultNatures = new Map<string, DictionaryItem>();
    private readonly _onDestroy$ = new Subject<void>();

    get DICTIONARY_NAME_NATURES(): string {
        return Dictionary.names.NATURES;
    }

    get user$(): Observable<User> {
        return this._userService.last$;
    }

    ngOnInit(): void {
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(clicked => {
            if (clicked.action === ConfigurationActionsMainComponent.actions.SAVE) {
                this._callToActionService.actionExec$(this.save$()).subscribe();
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    save(): void {
        this.save$().subscribe();
    }

    save$(): Observable<void> {
        if (!this.dossiersForm.valid) {
            return this._modalService.openInformation$({
                comments: AppLayoutConfigurationDossiersComponent.errorMessages.OTHER,
                title: AppLayoutConfigurationDossiersComponent.errorMessages.TITLE,
                status: ModalService.status.WARNING,
            });
        }

        return this.user$.pipe(
            tap(user => this._defaultNatures.forEach((value, key) => user.settings.getUserSettingsDossiers(key).defaultNature = value)),
            switchMap(user => this._userSettingsFactory.save$(user, user.settings)),
            map(_ => undefined),
            take(1),
        );
    }

    selectDefaultNature(dossierType: string, dictionarySelected: DictionarySelected): void {
        this._defaultNatures.set(dossierType, dictionarySelected as DictionaryItem);
    }
}
