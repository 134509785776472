import {inject, NgModule} from '@angular/core';
import {NgLocationMandatsComponent} from '@shared/angularJS/up-ng/locations/locations-item-mandats.component';
import {NgLocationLifetimeComponent} from '@shared/angularJS/up-ng/locations/locations-item-lifetime.component';
import {NgLocationGestionComponent} from '@shared/angularJS/up-ng/locations/locations-item-gestion.component';
import {NgLocationDiffusionComponent} from '@shared/angularJS/up-ng/locations/locations-item-diffusion.component';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {FeaturesModule} from '@features/features.module';
import {NgBiensSearchComponent} from '@shared/angularJS/up-ng/biens/biens.search.component';
import {
    AppLayoutLocationsSearchComponent
} from '@core/routing/layout/locations/search/layout-locations-search.component';
import {
    AppLayoutLocationsWalletComponent
} from '@core/routing/layout/locations/wallet/layout-locations-wallet.component';
import {AppLayoutLocationComponent} from '@core/routing/layout/locations/location/layout-location.component';
import {BonvisitesActionsMainComponent} from '@features/bonvisites/list/actions/bonvisites.actions-main.component';
import {AppLayoutDossierSuiviComponent} from '@core/routing/layout/suivi/layout-dossier-suivi.component';
import {
    NgEskimmoDossierSuiviReportComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {
    NgEskimmoDossierSuiviEmailComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {CanDeactivateGuard} from '@core/routing/guards/can-deactivate/can-deactivate-guard.service';
import {
    AppLayoutLocationEditComponent
} from '@core/routing/layout/locations/location/edit/layout-location-edit.component';
import {
    AppLayoutLocationEditGeneralComponent
} from '@core/routing/layout/locations/location/edit/general/layout-location-edit-general.component';
import {
    AppLayoutBienEditConstructionComponent
} from '@core/routing/layout/biens/bien/edit/construction/layout-bien-edit-construction.component';
import {
    AppLayoutBienEditFormalitesComponent
} from '@core/routing/layout/biens/bien/edit/formalites/layout-bien-edit-formalites.component';
import {
    AppLayoutDossierFilesComponent
} from '@core/routing/layout/dossiers/dossier/files/layout-dossier-files.component';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {
    AppLayoutLocationsPublicitesComponent
} from '@core/routing/layout/locations/publicites/layout-locations.publicites.component';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {AppLayoutBonvisitesComponent} from '@core/routing/layout/bonvisites/layout-bonvisites.component';
import {AppLayoutBonvisiteComponent} from '@core/routing/layout/bonvisites/bonvisite/layout-bonvisite.component';
import {
    AppLayoutBonvisitesWalletComponent
} from '@core/routing/layout/bonvisites/wallet/layout-bonvisites-wallet.component';
import {
    AppLayoutBonvisiteEditComponent
} from '@core/routing/layout/bonvisites/bonvisite/edit/layout.bonvisite-edit.component';
import {
    AppLayoutBonvisiteWriteComponent
} from '@core/routing/layout/bonvisites/bonvisite/write/layout.bonvisite-write.component';
import {
    AppLayoutBonvisiteResumeComponent
} from '@core/routing/layout/bonvisites/bonvisite/resume/layout.bonvisite-resume.component';
import {
    AppLayoutBonvisiteSignComponent
} from '@core/routing/layout/bonvisites/bonvisite/sign/layout.bonvisite-sign.component';
import {GuardsService} from '@core/routing/guards/guards.service';
import {CanDeactivateComponent} from '@core/routing/guards/guards.interfaces';
import {NgBienDetailsComponent} from '@shared/angularJS/up-ng/biens/biens-item-details.component';
import {NgBiensWalletDiffusionComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-diffusion.component';
import {NgBiensWalletCurrentComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-current.component';
import {NgBiensLastCriteriaComponent} from '@shared/angularJS/up-ng/biens/biens-last-criteria.component';
import {
    NgBiensWalletSearchCriteriaComponent
} from '@shared/angularJS/up-ng/biens/biens-wallet-search-criteria.component';
import {NgBienEditPhotosComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-photos.component';
import {NgBienEditVirtualVisitComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-virtual_visit.component';
import {ActualitesService} from '@models/actualites/actualites.service';
import {DossierAccessGuardService} from '@features/dossiers/dossier/new-guard/dossier.access-guard.service';

const routes: Routes = [
    {component: AppLayoutLocationsPublicitesComponent, path: 'informations'},
    {
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {[GuardsService.REDIRECTION_ROUTE_DATA]: '/app/locations/informations', roles: [User.roles.GRP_LOCATION]},
        path: '',
        children: [
            {component: NgBienDetailsComponent, path: 'details/:locationId'},
            {component: NgBiensLastCriteriaComponent, path: 'last/criteres'},
            {
                component: AppLayoutLocationsWalletComponent,
                path: 'portefeuille',
                children: [
                    {component: NgBiensWalletDiffusionComponent, path: 'diffusion'},
                    {
                        component: NgBiensWalletCurrentComponent,
                        path: '',
                        resolve: {news: () => inject(ActualitesService).show$()},
                    },
                    // Wildcard pour /app/locations/portefeuille/*
                    {path: '**', pathMatch: 'full', redirectTo: ''},
                ],
            },
            {
                component: AppLayoutLocationsSearchComponent,
                path: 'search',
                children: [
                    {component: NgBiensWalletSearchCriteriaComponent, path: 'criteres'},
                    {component: NgBienDetailsComponent, path: 'details/:locationId'},
                    {component: NgBiensSearchComponent, path: ''},
                    // Wildcard pour /app/locations/search/*
                    {path: '**', pathMatch: 'full', redirectTo: ''},
                ],
            },
            {
                component: AppLayoutLocationComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) =>
                        inject(DossierAccessGuardService).canActivate(activatedRouteSnapshot, routerStateSnapshot),
                ],
                path: ':locationId',
                children: [
                    {
                        component: AppLayoutBonvisitesComponent,
                        path: 'bons-visite',
                        children: [
                            {
                                component: AppLayoutBonvisitesWalletComponent,
                                data: {actions: {main: {component: BonvisitesActionsMainComponent}}},
                                path: 'portefeuille',
                            },
                            {
                                component: AppLayoutBonvisiteComponent,
                                path: ':bonvisiteUuid',
                                children: [
                                    {component: AppLayoutBonvisiteEditComponent, path: 'edit'},
                                    {component: AppLayoutBonvisiteWriteComponent, path: 'redaction'},
                                    {component: AppLayoutBonvisiteResumeComponent, path: 'resume'},
                                    {component: AppLayoutBonvisiteSignComponent, path: 'signature'},
                                ],
                            },
                            // Wildcard pour /app/locations/:locationId/bons-visite/*
                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                        ],
                    },
                    {component: NgLocationDiffusionComponent, path: 'diffusion'},
                    {
                        component: AppLayoutDossierFilesComponent,
                        data: {
                            actions: {
                                main: {component: DossierFilesActionsMainComponent},
                                selection: {component: DossierFilesActionsSelectionComponent},
                            },
                        },
                        path: 'documents',
                    },
                    {
                        canDeactivate: [
                            (component: CanDeactivateComponent) => inject(CanDeactivateGuard).canDeactivate(component),
                        ],
                        component: AppLayoutLocationEditComponent,
                        path: 'edit',
                        children: [
                            {component: AppLayoutBienEditConstructionComponent, path: 'construction'},
                            {component: AppLayoutBienEditFormalitesComponent, path: 'formalites'},
                            {component: AppLayoutLocationEditGeneralComponent, path: 'general'},
                            {component: NgBienEditPhotosComponent, path: 'photos'},
                            {component: NgBienEditVirtualVisitComponent, path: 'virtual_visit'},
                            // Wildcard pour /app/locations/:locationId/edit/*
                            {path: '**', pathMatch: 'full', redirectTo: 'general'},
                        ],
                    },
                    {component: NgLocationGestionComponent, path: 'gestion'},
                    {component: NgLocationLifetimeComponent, path: 'lifetime'},
                    {component: NgLocationMandatsComponent, path: 'mandats'},
                    {
                        component: AppLayoutDossierSuiviComponent,
                        path: 'suivi',
                        children: [
                            {component: NgEskimmoDossierSuiviEmailComponent, path: 'emails/:emailId'},
                            {component: NgEskimmoDossierSuiviReportComponent, path: 'reports/:reportId'},
                            // Wildcard pour /app/locations/:locationId/suivi/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    // Wildcard pour /app/locations/:locationId/!*
                    {path: '**', pathMatch: 'full', redirectTo: 'edit/general'},
                ],
            },
            // Wildcard pour /app/locations/!*
            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
        ],
    },
];

@NgModule({
    declarations: [
        AppLayoutLocationComponent,
        AppLayoutLocationEditComponent,
        AppLayoutLocationEditGeneralComponent,
        AppLayoutLocationsPublicitesComponent,
        AppLayoutLocationsSearchComponent,
        AppLayoutLocationsWalletComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class LocationsRoutingModule {
}
