import {Component, inject, Input, OnInit, output} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import Procedure from '@models/procedures/procedure/procedure.model';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCProcedureSignataireSignComponent
} from '@features/procedures/procedure/signataires/signataire/sign/procedure-signataire.sign.component';

@Component({
    selector: 'app-procedure-presentiel-sign',
    standalone: false,
    templateUrl: 'procedure.presentiel.sign.component.html',
})
export class AppProcedurePresentielSignComponent implements OnInit {
    readonly signed = output<void>();
    private _modalService = inject(ModalService);
    private _procedureService = inject(ProcedureService);
    private _cSignataires$!: Observable<CProcedureSignataires>;
    private _pdfPathSubject = new BehaviorSubject<string>(undefined!);
    private _pdfPath$ = this._pdfPathSubject.asObservable();
    private _procedure!: Procedure;

    get cSignataires$(): Observable<CProcedureSignataires> {
        return this._cSignataires$;
    }

    @Input()
    set cSignataires$(value: Observable<CProcedureSignataires>) {
        this._cSignataires$ = value;
    }

    get pdfPath$(): Observable<string> {
        return this._pdfPath$;
    }

    @Input()
    set procedure(value: Procedure) {
        this._procedure = value;
    }

    ngOnInit(): void {
        this.updatePDFPath();
    }

    onSelectProcedureSignataire(signataire: ProcedureSignataire, focusButton: HTMLButtonElement): void {
        this._modalService.open$(DCProcedureSignataireSignComponent, {procedureSignataire: signataire}).pipe(
            map(signature => signature as string),
            switchMap(signature => {
                if (!signature) {
                    return of(undefined!);
                }

                return this._procedureService.signataireSign$(this._procedure, signataire, signature).pipe(
                    tap(_ => this.updatePDFPath()),
                    tap(_ => this.signed.emit()),
                );
            }),
            take(1),
        ).subscribe(_ => focusButton.blur());
    }

    updatePDFPath(): void {
        this._pdfPathSubject.next(undefined!);
        this._procedureService.getMedia$(this._procedure).pipe(
            map(media => media.linkDownload),
            take(1),
        ).subscribe(pdfPath => this._pdfPathSubject.next(pdfPath));
    }
}
