import {Component, Input} from '@angular/core';

// @todo Créer options et y mettre addedClass, loading et width
// @todo Quand il y aura loading, vérifier quelles images conserver en "lazy" ou non
@Component({
    selector: 'app-ng2-img',
    standalone: false,
    // "tw-inline" est obligatoire pour éviter le normalize de tailwind
    template: '<img src="{{src}}" [ngStyle]="{width}" [ngClass]="addedClass" class="tw-inline" loading="lazy">',
})
export class Ng2ImgComponent {
    private _addedClass!: string;
    private _src!: string;
    private _width = '100%';

    get addedClass(): string {
        return this._addedClass;
    }

    @Input()
    set addedClass(value: string) {
        this._addedClass = value;
    }

    get src(): string {
        return this._src;
    }

    @Input()
    set src(value: string) {
        this._src = value;
    }

    get width(): string {
        return this._width;
    }

    @Input()
    set width(value: string) {
        this._width = value;
    }
}
