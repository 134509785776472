import {Component, Input} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {NgClass} from '@angular/common';

@Component({
    imports: [FaIconComponent, NgClass],
    selector: 'app-modal-status-icon',
    templateUrl: 'modal.status-icon.component.html',
})
export class ModalStatusIconComponent {
    readonly ModalStatus = ModalService.status;
    private _status!: string;

    get status(): string {
        return this._status;
    }

    @Input()
    set status(value: string) {
        this._status = value;
    }
}
