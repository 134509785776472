import {inject, Injectable} from '@angular/core';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {ContactsGroupMemberFactory} from '@models/contacts-group/members/member/contacts-group-member.factory';
import {IContactsGroupMemberApi} from '@models/contacts-group/members/member/contacts-group-member.interfaces';
import {NgContactsGroupMember} from '@legacy/app/managers/ressources';
import Contact from '@models/contacts/contact/contact.model';
import {combineLatest, Observable} from 'rxjs';
import {ContactsGroupMemberService} from '@models/contacts-group/members/member/contacts-group-member.service';
import ContactsGroup from '@models/contacts-group/contacts-group.model';

@Injectable({providedIn: 'root'})
export class ContactsGroupMembersService {
    private _contactsGroupMemberFactory = inject(ContactsGroupMemberFactory);
    private _contactsGroupMemberService = inject(ContactsGroupMemberService);

    create(contactsGroupMemberApis: IContactsGroupMemberApi[]): ContactsGroupMember[] {
        return (contactsGroupMemberApis || []).filter(contactsGroupMemberApi => contactsGroupMemberApi)
            .map(contactsGroupMemberApi => this._contactsGroupMemberFactory.create(contactsGroupMemberApi));
    }

    createFromContact(contact: Contact): ContactsGroupMember[] {
        return [this._contactsGroupMemberFactory.createFromContact(contact)];
    }

    save$(contactsGroup: ContactsGroup, contactsGroupMembers: ContactsGroupMember[]): Observable<ContactsGroupMember[]> {
        return combineLatest(contactsGroupMembers.map(contactsGroupMember => this._contactsGroupMemberService.save$(contactsGroup, contactsGroupMember)));
    }

    ngCreate(ngContactsGroupMembers: NgContactsGroupMember[]): ContactsGroupMember[] {
        return (ngContactsGroupMembers || []).filter(ngContactsGroupMember => ngContactsGroupMember)
            .map(ngContactsGroupMember => this._contactsGroupMemberFactory.ngCreate(ngContactsGroupMember));
    }
}
