import {Component, Input} from '@angular/core';
import Collection from '@models/collection/collection.model';
import {Observable} from 'rxjs';
import {IModel} from '@models/model.interfaces';
import {ICollectionStackedImagesOptions} from '@shared/collection/collection.interfaces';
import {AsyncPipe, NgOptimizedImage, NgPlural} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppLoaderComponent, AsyncPipe, FaIconComponent, NgOptimizedImage, NgPlural],
    selector: 'app-collection-stacked-images',
    templateUrl: 'collection.stacked-images.component.html',
})
export class AppCollectionStackedImagesComponent {
    static readonly initCollectionStackedImagesOptions: ICollectionStackedImagesOptions = {
        labelPlural: 'éléments',
        labelSingular: 'élément',
        linkImageAttribute: 'linkImage',
        quantity: 5,
    };

    private _collection$!: Observable<Collection<IModel>>;
    private _options: ICollectionStackedImagesOptions = {...AppCollectionStackedImagesComponent.initCollectionStackedImagesOptions};

    get collection$(): Observable<Collection<IModel>> {
        return this._collection$;
    }

    @Input()
    set collection$(value$: Observable<Collection<IModel>>) {
        this._collection$ = value$;
    }

    get options(): ICollectionStackedImagesOptions {
        return this._options;
    }

    @Input()
    set options(value: ICollectionStackedImagesOptions) {
        this._options = {...AppCollectionStackedImagesComponent.initCollectionStackedImagesOptions, ...value};
    }

    // @todo Passer par un attribut calculé plutôt que par une méthode qui fait un calcul à chaque fois
    remainingNumber(collection: Collection): number {
        if (!this._options.quantity || collection.total < this._options.quantity) {
            return 0;
        }

        return collection.total - this._options.quantity;
    }
}
