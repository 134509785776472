import {Component, Input, output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import Contact from '@models/contacts/contact/contact.model';
import {IContactCardOptions, IContactConvivialNameOptions} from '@features/contacts/contacts.interfaces';

@Component({selector: 'app-contact-card', standalone: false, templateUrl: 'contact.card.component.html'})
export class AppContactCardComponent {
    readonly clicked = output<Contact>();
    static readonly initContactCardOptions: IContactCardOptions = {enabledClick: true};
    private _contactConvivialNameOptions: IContactConvivialNameOptions = {};
    private _contactSource = new BehaviorSubject<Contact>(undefined!);
    private _contact$ = this._contactSource.asObservable();
    private _options: IContactCardOptions = {...AppContactCardComponent.initContactCardOptions};


    get contact$(): Observable<Contact> {
        return this._contact$;
    }

    @Input()
    set contact(value: Contact) {
        this._contactSource.next(value);
    }

    get contactConvivialNameOptions(): IContactConvivialNameOptions {
        return this._contactConvivialNameOptions;
    }

    get options(): IContactCardOptions {
        return this._options;
    }

    @Input()
    set options(value: IContactCardOptions) {
        this._options = {...AppContactCardComponent.initContactCardOptions, ...value};
        this._contactConvivialNameOptions.highlight = this._options.highlight;
        this._contactConvivialNameOptions.withExternalSource = this._options.withExternalSource;
    }

    onClick(contact: Contact): void {
        if (!this._options.enabledClick) {
            return;
        }

        this.clicked.emit(contact);
    }
}
