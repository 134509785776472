import {Component, inject, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {IContactConvivialNameOptions} from '@features/contacts/contacts.interfaces';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {IContactsGroupConvivialNameOptions} from '@features/contacts-group/contactsGroup.interfaces';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';

@Component({
    selector: 'app-contacts-group-convivial-name',
    standalone: false,
    templateUrl: 'contacts-group.convivial-name.component.html',
})
export class AppContactsGroupConvivialNameComponent {
    static readonly initContactsGroupConvivialNameOptions: IContactsGroupConvivialNameOptions = {};
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _contactsGroup$!: Observable<ContactsGroup>;
    private _options: IContactsGroupConvivialNameOptions = {...AppContactsGroupConvivialNameComponent.initContactsGroupConvivialNameOptions};

    get contactsGroup$(): Observable<ContactsGroup> {
        return this._contactsGroup$;
    }

    @Input()
    set linkContactsGroup(value: string) {
        this._contactsGroup$ = this._contactsGroupFactory.getByLink$(value);
    }

    @Input()
    set linkDossier(value: string) {
        this._dossierBienFactory.getByLink$(value).pipe(
            take(1)
        ).subscribe(dossier => {
            if (dossier.interne && dossier.linkContactsGroup) {
                this.linkContactsGroup = dossier.linkContactsGroup;
            } else {
                const contactGroupExterne = this._contactsGroupFactory.createVirgin();
                contactGroupExterne.nom = dossier.interne ? '' : 'Dossier d\'un confrère';
                this._contactsGroup$ = of(contactGroupExterne);
            }
        });
    }

    get options(): IContactConvivialNameOptions {
        return this._options;
    }

    @Input()
    set options(value: IContactConvivialNameOptions) {
        this._options = {...AppContactsGroupConvivialNameComponent.initContactsGroupConvivialNameOptions, ...value};
    }
}
