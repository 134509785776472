import {Component, ElementRef, inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import EtudeSettingsDocumentsHeader
    from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.model';
import {IIframeOptions} from '@shared/iframe/iframe.interfaces';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {ModalService} from '@shared/modal/modal.service';
import {ModalConfirmationComponent} from '@shared/modal/confirmation/modal.confirmation.component';
import {EditorOptions} from 'tinymce';
import TemplateHeader from '@models/templates/headers/header/template-header.model';
import {TemplateHeaderService} from '@models/templates/headers/header/template-header.service';
import {
    EtudeSettingsDocumentsHeaderService
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.service';
import {TemplateHeaderFactory} from '@models/templates/headers/header/template-header.factory';
import {IFormSelectImageInput} from '@shared/form/form.interfaces';
import {CTemplateHeadersService} from '@models/templates/headers/collection/template-headers.collection.service';
import CTemplateHeaders from '@models/templates/headers/collection/template-headers.collection.model';
import {
    EtudeSettingsDocumentsHeaderApiService
} from '@models/etudes/etude/settings/documents/header/etude-settings-documents-header.api.service';
import Template from '@models/templates/template/template.model';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'layout-configuration-documents-header',
    standalone: false,
    styleUrls: ['layout-configuration-documents-header.component.scss'],
    templateUrl: 'layout-configuration-documents-header.component.html',
})
export class AppLayoutConfigurationDocumentsHeaderComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {
        HEADER_CODE_VALIDATION: {
            buttonConfirmationLabel: 'Personnaliser',
            comments: 'En personnalisant le document, vous ne pourrez plus bénéficier des évolutions proposées par ' +
                AppNoteoIconTextNotyComponent.label + '.',
            question: 'Voulez-vous vraiment personnaliser votre document ?',
            title: 'Personnalisation d\'un document',
        },
    };
    private _callToActionService = inject(CallToActionService);
    private _cTemplateHeadersService = inject(CTemplateHeadersService);
    private _etudeSettingsDocumentsHeaderService = inject(EtudeSettingsDocumentsHeaderService);
    private _modalService = inject(ModalService);
    private _renderer2 = inject(Renderer2);
    private _templateHeaderFactory = inject(TemplateHeaderFactory);
    private _templateHeaderService = inject(TemplateHeaderService);
    private _tinymceService = inject(TinymceService);
    private _cTemplateHeadersSubject = new ReplaySubject<CTemplateHeaders>();
    private _cTemplateHeaders$ = this._cTemplateHeadersSubject.asObservable();
    private _editorOptions!: EditorOptions;
    private _header!: EtudeSettingsDocumentsHeader;
    private _header422Error = false;
    private _headerLoading = true;
    private _headerPreview$!: Observable<string>;
    private _headerPreviewOptions: IIframeOptions = {sandbox: 'allow-same-origin'};
    private _headerSubject = new ReplaySubject<EtudeSettingsDocumentsHeader>();
    private _heightInputSubject = new Subject<number>();
    private _heightTemplateSubject = new Subject<[number, string]>();
    private readonly _onDestroy$ = new Subject<void>();
    private _templateInputSubject = new Subject<string>();

    get cTemplateHeaders$(): Observable<CTemplateHeaders> {
        return this._cTemplateHeaders$;
    }

    get editorOptions(): EditorOptions {
        return this._editorOptions;
    }

    get header(): EtudeSettingsDocumentsHeader {
        return this._header;
    }

    get header422Error(): boolean {
        return this._header422Error;
    }

    get headerLoading(): boolean {
        return this._headerLoading;
    }

    get headerPreview$(): Observable<string> {
        return this._headerPreview$;
    }

    get headerPreviewOptions(): IIframeOptions {
        return this._headerPreviewOptions;
    }

    @ViewChild('preview')
    set preview(previewElement: ElementRef<HTMLDivElement>) {
        if (!previewElement) {
            return;
        }

        const heightPx = +this.headerPreviewOptions.height! + TinymceService.DOCUMENT_MARGIN * Template.convertMmToPx * 2 + 2;

        this._renderer2.setStyle(previewElement.nativeElement.firstChild, 'margin', TinymceService.DOCUMENT_MARGIN.toString() + 'mm');
        this._renderer2.setStyle(previewElement.nativeElement, 'width', '210mm');
        this._renderer2.setStyle(previewElement.nativeElement.parentElement, 'height', heightPx.toString() + 'px');
    }

    ngOnInit(): void {
        this._editorOptions = this._tinymceService.getOptions('complete', {
            content_css: [EtudeSettingsDocumentsHeaderApiService.CSS_FILE],
            plugins: [
                TinymceService.plugins.AUTO_RESIZE,
                TinymceService.plugins.BALISES,
            ],
            pluginsOptions: {
                [TinymceService.pluginOptions.balisesModels.KEY]: [
                    TinymceService.pluginOptions.balisesModels.value.etude.CODE,
                    TinymceService.pluginOptions.balisesModels.value.sitePrincipal.CODE,
                    TinymceService.pluginOptions.balisesModels.value.currentUser.CODE,
                ],
            },
        } as unknown as EditorOptions);

        // Saisie des templates-headers
        this._cTemplateHeadersService.getWithCustom$().pipe(take(1))
            .subscribe(cTemplateHeaders => this._cTemplateHeadersSubject.next(cTemplateHeaders));

        // Au clic sur le CTA
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(clicked => {
            switch (clicked.action) {
                case ConfigurationActionsMainComponent.actions.SAVE:
                    this.saveHeader();
                    break;
            }
        });

        // Listen Height and Template to Preview
        this._headerPreview$ = combineLatest([
            this._heightInputSubject.asObservable(),
            this._templateInputSubject.asObservable(),
        ]).pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(([headerHeightMm,]) => this._headerPreviewOptions.height = (headerHeightMm * Template.convertMmToPx).toString()),
            switchMap(([headerHeightMm, template]) => {
                const headerPreviewSubject = new BehaviorSubject<string>(undefined!);

                this._header422Error = false;
                this._templateHeaderFactory.getPreviewLink$(headerHeightMm, template, {headers: {handledStatusErrors: [422]}}).pipe(take(1))
                    .subscribe({
                        next: headerPreview => headerPreviewSubject.next(headerPreview),
                        error: (error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                this._header422Error = true;
                            }
                        },
                    });

                return headerPreviewSubject.asObservable();
            }),
        );

        // Manage Height
        this._heightTemplateSubject.asObservable().pipe(
            map(([height,]) => height),
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(height => this._heightInputSubject.next(height));

        // Manage Template
        this._heightTemplateSubject.asObservable().pipe(
            map(([, template]) => template),
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(template => this._templateInputSubject.next(template));

        // Get Height and Template from header
        this._headerSubject.asObservable().pipe(
            switchMap(header => this._templateHeaderService.getHeightTemplate$(header, this.cTemplateHeaders$)),
            takeUntil(this._onDestroy$),
        ).subscribe(heightTemplate => this._heightTemplateSubject.next(heightTemplate));

        // Init Header
        this._etudeSettingsDocumentsHeaderService.initCurrent();
        this._etudeSettingsDocumentsHeaderService.current$.pipe(
            filter(etudeSettingsDocumentsHeader => !!etudeSettingsDocumentsHeader),
            tap(etudeSettingsDocumentsHeader => this._header = etudeSettingsDocumentsHeader),
            tap(etudeSettingsDocumentsHeader => this._headerSubject.next(etudeSettingsDocumentsHeader)),
            take(1),
        ).subscribe(_ => this._headerLoading = false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    headerCodeValidation$(formSelectImageInput: IFormSelectImageInput): Observable<boolean> {
        const selectTemplateHeaderCode = formSelectImageInput?.code;

        let isSelectionAccepted: Observable<boolean> = of(true);

        if (this._header.code !== selectTemplateHeaderCode && selectTemplateHeaderCode === TemplateHeader.codes.CUSTOM) {
            isSelectionAccepted = this._modalService.open$(ModalConfirmationComponent, {
                buttonConfirmationLabel: AppLayoutConfigurationDocumentsHeaderComponent.messages.HEADER_CODE_VALIDATION.buttonConfirmationLabel,
                checkComments: false,
                comments: AppLayoutConfigurationDocumentsHeaderComponent.messages.HEADER_CODE_VALIDATION.comments,
                question: AppLayoutConfigurationDocumentsHeaderComponent.messages.HEADER_CODE_VALIDATION.question,
                title: AppLayoutConfigurationDocumentsHeaderComponent.messages.HEADER_CODE_VALIDATION.title,
                status: ModalService.status.WARNING,
            }).pipe(map(isAccepted => isAccepted as boolean));
        }

        return isSelectionAccepted;
    }

    saveHeader(): void {
        this._callToActionService.actionExec$(this._etudeSettingsDocumentsHeaderService.save$(this._header)).pipe(
            take(1),
        ).subscribe(_ => this.updateHeader());
    }

    selectTemplateHeader(selectTemplateHeaderCode: string, fromCurrentTemplateHeader = false): void {
        if (this._header.code === selectTemplateHeaderCode) {
            return;
        }

        let currentTemplateHeader: TemplateHeader;

        this.cTemplateHeaders$.pipe(
            tap(cTemplateHeaders => currentTemplateHeader = cTemplateHeaders.findByCode(this._header.code)),
            map(cTemplateHeaders => cTemplateHeaders.findByCode(selectTemplateHeaderCode)),
            tap(selectedTemplateHeader => {
                this._header.code = selectedTemplateHeader.code;
                if (fromCurrentTemplateHeader) {
                    this._header.customHeight = currentTemplateHeader.height;
                    this._header.customTemplate = currentTemplateHeader.template;
                } else {
                    this._header.customHeight = selectedTemplateHeader.height;
                    this._header.customTemplate = selectedTemplateHeader.template;
                }
            }),
            take(1),
        ).subscribe(_ => this.updateHeader());
    }

    selectTemplateHeaderCustom(): void {
        const formSelectImageInput = {code: TemplateHeader.codes.CUSTOM} as IFormSelectImageInput;

        this.headerCodeValidation$(formSelectImageInput).pipe(take(1)).subscribe(isAccepted => {
            if (!isAccepted) {
                return;
            }

            this.selectTemplateHeader(TemplateHeader.codes.CUSTOM, true);
        });
    }

    updateHeader(): void {
        this._header.customHeight = (+this._header.customHeight || 0);
        this._headerSubject.next(this._header);
    }
}
