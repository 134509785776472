import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    ICVentePasserellesApi
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.interfaces';
import CVentePasserelles from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.model';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import {VentePasserellesApiService} from '@models/ventes/vente/passerelles/vente-passerelles.api.service';
import Passerelle from '@models/passerelle/passerelle.model';
import Vente from '@models/ventes/vente/vente.model';

@Injectable({providedIn: 'root'})
export class CVentePasserellesFactory {
    private _ventePasserelleFactory = inject(VentePasserelleFactory);
    private _ventePasserellesApiService = inject(VentePasserellesApiService);

    create(cVentePasserellesApi: ICVentePasserellesApi): CVentePasserelles {
        const cVentePasserelles = new CVentePasserelles();

        cVentePasserelles.links = new Links(cVentePasserellesApi._links);
        cVentePasserelles.page = cVentePasserellesApi.page;
        cVentePasserelles.pages = cVentePasserellesApi.pages;
        cVentePasserelles.perPage = cVentePasserellesApi.limit;
        cVentePasserelles.total = cVentePasserellesApi.total;
        if (cVentePasserellesApi._embedded) {
            cVentePasserelles.results = (cVentePasserellesApi._embedded.items || []).filter(ventePasserelleApi => ventePasserelleApi)
                .map(ventePasserelleApi => this._ventePasserelleFactory.create(ventePasserelleApi));
        }

        return cVentePasserelles;
    }

    get$(vente: Vente): Observable<CVentePasserelles> {
        return this._ventePasserellesApiService.getCollection$(vente.id.toString()).pipe(map(cVentePasserellesApi => this.create(cVentePasserellesApi)));
    }

    getFromPasserelle$(passerelle: Passerelle): Observable<CVentePasserelles> {
        return this._ventePasserellesApiService.getCollectionFromPasserelle$(passerelle.id.toString()).pipe(map(cVentePasserellesApi => this.create(cVentePasserellesApi)));
    }

    getNext$(cVentePasserelles: CVentePasserelles): Observable<CVentePasserelles> {
        return this._ventePasserellesApiService.getCollectionByLink$(cVentePasserelles.links.next)
            .pipe(map(cVentePasserellesApi => this.create(cVentePasserellesApi)));
    }
}
