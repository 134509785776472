import {Component, Directive, EventEmitter, Injectable, Input, output} from '@angular/core';
import {NgForm, NgModel, ValidationErrors} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs';
import {
    IFormButtonInput, IFormCheckboxListOptions, IFormFieldCheckbox, IFormFieldInput, IFormFilePickerOptions,
    IFormRadioInputYnn, IFormRadioOptions, IFormRadioYnnOptions, IFormRangeSliderInput, IFormRangeSliderOptions,
    IFormSearchInputOptions, IFormSelectDateOptions, IFormSelectDatetimeOptions, IFormSelectImageInput,
    IFormSelectImageInputOptions, IFormSelectInputOptions, IFormSelectInputOptionsGroupValueReturn
} from '@shared/form/form.interfaces';
import {Options} from '@angular-slider/ngx-slider';

export const getMockDataTransfer = (): DataTransfer => ({
    items: [getMockDataTransferItem(), getMockDataTransferItem()],
} as unknown as DataTransfer);

export const getMockDataTransferItem = (): DataTransferItem => ({webkitGetAsEntry: () => getMockFileSystemEntry()} as DataTransferItem);

export const getMockFileSystemEntry = (data = {}): FileSystemEntry => ({...{isFile: true}, ...data} as FileSystemEntry);

export const getMockFileSystemFileEntry = (data = {}): FileSystemFileEntry => ({
    ...{
        file: () => {
        },
    },
    ...getMockFileSystemEntry(),
    ...data,
} as FileSystemFileEntry);

export const getMockFormCheckboxListOptions = (suffix = 'form-checkbox-list-options'): IFormCheckboxListOptions => ({
    bindLabel: 'bindLabel-' + suffix,
    bindLinkImage: 'bindLinkImage-' + suffix,
    disabledUuids: ['disabled-uuid-1-' + suffix, 'disabled-uuid-2-' + suffix],
    name: 'name-' + suffix,
});

export const getMockFormData = (): FormData => new FormData();

export const getMockFormFieldCheckbox = (data = {}): IFormFieldCheckbox => {
    const formFieldCheckbox = data as IFormFieldCheckbox;
    const suffix = 'form-field-checkbox';

    return {uuid: formFieldCheckbox.uuid ?? 'uuid-' + suffix};
};

export const getMockFormFieldInput = (suffix = 'form-field-input'): IFormFieldInput => ({
    code: 'code-' + suffix,
    label: 'label-' + suffix,
});

export const getMockFormFilePickerOptions = (suffix = 'form-field-picker-options'): IFormFilePickerOptions => ({
    allowedTypes: 'allowedTypes-' + suffix,
    label: 'label-' + suffix,
    labelPadding: 'labelPadding-' + suffix,
    multiple: true,
    withIcon: true,
});

export const getMockFormButtonInput = (): IFormButtonInput => {
    const suffix = 'form-button-input';
    const formRadioInputYnn = getMockFormFieldInput(suffix) as IFormButtonInput;

    formRadioInputYnn.disabled = true;

    return formRadioInputYnn;
};

export const getMockFormRadioInputYnn = (): IFormRadioInputYnn => {
    const suffix = 'form-radio-input-ynn';
    const formRadioInputYnn = getMockFormFieldInput(suffix) as IFormRadioInputYnn;

    formRadioInputYnn.value = true;

    return formRadioInputYnn;
};

export const getMockFormRadioOptions = (suffix = 'form-radio-options'): IFormRadioOptions => ({
    classInput: 'classInput-' + suffix,
    classLabel: 'classLabel-' + suffix,
    classList: 'classList-' + suffix,
    name: 'name-' + suffix,
});

export const getMockFormRadioYnnOptions = (): IFormRadioYnnOptions => {
    const suffix = 'form-radio-ynn-options';

    return {...{accord: 'plural', genre: 'female'}, ...getMockFormRadioOptions(suffix)};
};

export const getMockFormRangeSliderInput = (): IFormRangeSliderInput => ({
    min: 3,
    max: 8,
});

export const getMockFormRangeSliderOptions = (): IFormRangeSliderOptions => ({
    ...getMockNgxSliderOptions(),
    ...{bornesNull: true},
});

export const getMockFormSearchInputOptions = (suffix = 'form-search-input-options'): IFormSearchInputOptions => ({
    hasFocus: false,
    placeholder: 'placeholder-' + suffix,
});

export const getMockFormSelectDateOptions = (suffix = 'form-select-date-options'): IFormSelectDateOptions => {
    const formSelectDateOptions = getMockFormSelectDatetimeOptions(suffix) as IFormSelectDateOptions;

    formSelectDateOptions.inputClass = 'inputClass-' + suffix;
    formSelectDateOptions.toApi = true;

    return formSelectDateOptions;
};

export const getMockFormSelectDatetimeOptions = (suffix = 'form-select-datetime-options'): IFormSelectDatetimeOptions => ({
    max: 'max-' + suffix,
    min: 'min-' + suffix,
    name: 'name-' + suffix,
    required: true,
});

export const getMockFormSelectImageInput = (): IFormSelectImageInput => {
    const suffix = 'form-select-image-input';

    return {code: 'code' + suffix, label: 'label' + suffix, thumbnailUrl: 'thumbnailUrl' + suffix};
};

export const getMockFormSelectImageInputOptions = (): IFormSelectImageInputOptions => {
    const suffix = 'form-select-image-input-options';

    return {aspectRatio: 'aspectRatio-' + suffix, selectionAccepted$: () => of(true)};
};

export const getMockFormSelectInputOptions = (suffix = 'form-select-input-options'): IFormSelectInputOptions => ({
    bindLabel: 'bindLabel-' + suffix,
    bindLinkImage: 'bindLinkImage-' + suffix,
    customTemplate: 'customTemplate-' + suffix,
    disabled: true,
    initWithEmptySearch: true,
    loadingText: 'loadingText-' + suffix,
    multiple: true,
    name: 'name-' + suffix,
    notFoundText: 'notFoundText-' + suffix,
    placeholder: 'placeholder-' + suffix,
    required: true,
    searchable: true,
    typeToSearchText: 'typeToSearchText-' + suffix,
    virtualScroll: true,
    groupBy: () => suffix,
    groupValue: () => getMockFormSelectInputOptionsGroupValueReturn(),
});

export const getMockFormSelectInputOptionsGroupValueReturn = (suffix = 'form-select-input-options-group-value-return'): IFormSelectInputOptionsGroupValueReturn => ({
    labelPlural: 'labelPlural-' + suffix,
    labelSingular: 'labelSingular-' + suffix,
    total: 7,
});

export const getMockFormValidationErrors = (): ValidationErrors => ({field1: true});

export const getMockNgForm = (data?: unknown): NgForm => ({
    ...{
        controls: {},
        ngSubmit: new EventEmitter<unknown>(),
        onSubmit: () => {
        },
        submitted: true,
        valid: true,
    } as unknown as NgForm,
    ...data as NgForm,
} as NgForm);

export const getMockNgModelForm = (data?: unknown): NgModel => {
    const suffix = 'ng-model';
    const valueChangesSource = new Subject();

    return {
        ...{
            errors: null,
            formDirective: getMockNgForm(),
            name: 'name-' + suffix,
            valueChanges: valueChangesSource.asObservable(),
            valueChangesSource,
        } as unknown as NgModel,
        ...data as NgModel,
    } as NgModel;
};

export const getMockNgxSliderOptions = (): Options => ({ceil: 5, floor: 2} as Options);

// @todo À placer dans shared.fixtures
// @todo Utilité avec getMockElementRef() ?
export class MockElementRef {
    // @todo getMockHTMLElement() ?
    nativeElement = {
        firstChild: {localName: 'localName'},
        localName: 'localName',
        parentElement: {id: 'idParentElement'},
        textContent: 'textContentéÀîôùç$?*.jpg',
        scrollHeight: 10,
        style: {},
        contains: (): void => {
        },
        focus: (): void => {
        },
        getElementsByTagName: (): HTMLCollectionOf<HTMLElement> => ({} as HTMLCollectionOf<HTMLElement>),
        querySelector: (): HTMLElement => ({} as HTMLElement),
        querySelectorAll: (): HTMLElement[] => ([{}, {}] as HTMLElement[]),
        setAttribute: (): void => {
        },
        scrollIntoView: (): void => {
        },
    };
}

@Component({selector: 'app-form-checkbox-list', template: ''})
export class MockAppFormCheckboxListComponent {
    @Input() list!: IFormFieldCheckbox[];
    @Input() model!: IFormFieldCheckbox[];
    @Input() options!: IFormCheckboxListOptions;
    readonly selected = output<IFormFieldCheckbox>();
}

@Component({selector: 'app-form-error', template: ''})
export class MockAppFormErrorComponent {
    @Input() error!: string;
}

@Component({selector: 'app-form-file-picker', template: ''})
export class MockAppFormFilePickerComponent {
    @Input() openFilePicker$!: Observable<void>;
    @Input() options!: IFormFilePickerOptions;
    readonly selected = output<File>();
}

@Component({exportAs: 'formRadio', selector: 'app-form-radio', template: ''})
export class MockAppFormRadioComponent {
    formInput = getMockNgModelForm();
    @Input() list!: IFormFieldInput[];
    @Input() model!: IFormFieldInput;
    @Input() options!: IFormRadioOptions;
    readonly selected = output<IFormFieldInput>();
}

@Component({exportAs: 'formRadioYnn', selector: 'app-form-radio-ynn', template: ''})
export class MockAppFormRadioYnnComponent {
    @Input() model!: boolean;
    readonly selected = output<boolean>();
}

@Component({selector: 'app-form-range-slider', template: ''})
export class MockAppFormRangeSliderComponent {
    @Input() options!: IFormRangeSliderOptions;
    @Input() range!: IFormRangeSliderInput;
    readonly rangeUpdated = output<IFormRangeSliderInput>();
}

@Component({exportAs: 'formSearchInput', selector: 'app-form-search-input', template: ''})
export class MockAppFormSearchInputComponent {
    @Input() loading!: boolean;
    @Input() model!: string;
    @Input() options!: IFormSearchInputOptions;
    readonly searchedText = output<string>();
}

@Component({selector: 'app-form-select-button', template: ''})
export class MockAppFormSelectButtonComponent {
    @Input() list!: IFormButtonInput[];
    @Input() model!: string;
    readonly selected = output<string>();
}

@Component({exportAs: 'formSelectDate', selector: 'app-form-select-date', template: ''})
export class MockAppFormSelectDateComponent {
    formInput = getMockNgModelForm();
    @Input() model!: string;
    @Input() options!: IFormSelectDateOptions;
    readonly selected = output<string>();
}

@Component({exportAs: 'formSelectDatetime', selector: 'app-form-select-datetime', template: ''})
export class MockAppFormSelectDatetimeComponent {
    formInput = getMockNgModelForm();
    @Input() model!: string;
    @Input() options!: IFormSelectDatetimeOptions;
    readonly selected = output<string>();
}

@Component({exportAs: 'formSelectImage', selector: 'app-form-select-image', template: ''})
export class MockAppFormSelectImageComponent {
    @Input() list!: IFormSelectImageInput[];
    @Input() model!: IFormSelectImageInput;
    @Input() options!: IFormSelectImageInputOptions;
    readonly selected = output<string>();
}

@Component({exportAs: 'formSelectInput', selector: 'app-form-select-input', template: ''})
export class MockAppFormSelectInputComponent {
    formInput = getMockNgModelForm();
    @Input() list!: unknown[];
    @Input() loading!: boolean;
    @Input() model!: unknown;
    @Input() options!: IFormSelectInputOptions;
    readonly search = output<string>();
    readonly selected = output<unknown>();
}

@Component({selector: 'app-form-switch', template: ''})
export class MockAppFormSwitchComponent {
    @Input() loading!: boolean;
    @Input() model!: unknown;
    readonly clicked = output<boolean>();
}

@Component({selector: 'ng-select', template: ''})
export class MockNgSelectComponent {
    @Input() bindLabel!: string;
    @Input() clearable!: boolean;
    @Input() clearAllText!: string;
    @Input() disabled!: boolean;
    @Input() items!: unknown[];
    @Input() loading!: boolean;
    @Input() loadingText!: string;
    @Input() multiple!: boolean;
    @Input() ngModel?: unknown;
    @Input() placeholder!: string;
    @Input() searchable!: boolean;
    @Input() typeahead!: Subject<string>;
    @Input() typeToSearchText!: string;
    @Input() virtualScroll!: boolean;
    @Input() groupBy!: (item: unknown) => string;
    @Input() groupValue!: (key: string, children: unknown[]) => IFormSelectInputOptionsGroupValueReturn;

    // eslint-disable-next-line @angular-eslint/no-output-native
    readonly change = output<void>();
    readonly clear = output<void>();
}

@Component({selector: 'ngx-slider', template: ''})
export class MockNgxSliderComponent {
    @Input() highValue!: number;
    @Input() options!: Options;
    @Input() value!: number;
    readonly userChange = output<void>();
}

@Directive({selector: '[appFormError]'})
export class MockAppFormErrorDirective {
    @Input() appFormError!: NgModel;
}

@Directive({selector: '[appFormFileDropper]'})
export class MockAppFormFileDropperDirective {
    readonly dragoverActiv = output<boolean>();
    readonly droppedFile = output<File>();
    @Input() isDroppable!: boolean;
}

@Directive({selector: 'textarea'})
export class MockAppFormTextareaAutoResizeDirective {
    @Input() minHeight!: number;
}

@Directive({exportAs: 'ngModel', selector: '[ngModel]'})
export class MockNgModelDirective {
    @Input() ngModel: unknown;
}

@Directive({selector: '[appFormEmailValidator]'})
export class MockFormEmailValidatorsDirective {
}

@Directive({selector: '[appFormPhoneValidator]'})
export class MockFormPhoneValidatorsDirective {
}

@Directive({selector: '[appProvideParentForm]'})
export class MockProvideParentFormDirective {
}

@Injectable()
export class MockNgForm {
    controls = {};
    submitted = true;
}
