import {Component, inject} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IModalConfirmationData} from '@shared/modal/modal.interfaces';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ModalStatusIconComponent} from '@shared/modal/status-icon/modal.status-icon.component';
import {NgClass} from '@angular/common';

@Component({
    imports: [FormsModule, ModalStatusIconComponent, NgClass],
    selector: 'app-modal-confirmation',
    templateUrl: 'modal.confirmation.component.html',
})
export class ModalConfirmationComponent implements IDynamicComponent {
    readonly ModalStatus = ModalService.status;
    private _modalService = inject(ModalService);
    private _buttonCancelLabel!: string;
    private _buttonConfirmationLabel!: string;
    private _checkComments!: boolean;
    private _comments!: string;
    private _isCheckComments = false;
    private _question!: string;
    private _status!: string;
    private _title!: string;

    get buttonCancelLabel(): string {
        return this._buttonCancelLabel;
    }

    get buttonConfirmationLabel(): string {
        return this._buttonConfirmationLabel;
    }

    get checkComments(): boolean {
        return this._checkComments;
    }

    get comments(): string {
        return this._comments;
    }

    set data(modalConfirmationData: IModalConfirmationData) {
        this._buttonCancelLabel = modalConfirmationData.buttonCancelLabel ?? 'Annuler';
        this._buttonConfirmationLabel = modalConfirmationData.buttonConfirmationLabel ?? 'Oui';
        this._checkComments = modalConfirmationData.checkComments === true;
        this._comments = modalConfirmationData.comments!;
        this._question = modalConfirmationData.question;
        this._title = modalConfirmationData.title;
        this._status = modalConfirmationData.status!;
    }

    get isCheckComments(): boolean {
        return this._isCheckComments;
    }

    set isCheckComments(value: boolean) {
        this._isCheckComments = value;
    }

    get question(): string {
        return this._question;
    }

    get status(): string {
        return this._status;
    }

    get title(): string {
        return this._title;
    }

    response(ngForm: NgForm, isAccepted: boolean): void {
        // @todo Vérifier si on peut simplifier par ngForm.valid
        if (!ngForm.form.valid && isAccepted) {
            return;
        }

        this._modalService.setResponse(isAccepted);
    }
}
