import {Component, Input} from '@angular/core';

@Component({selector: 'app-ng2-date', standalone: false, template: '{{ date | date: format }}'})
export class Ng2DateComponent {
    private _date!: string;
    private _format!: string;

    get date(): string {
        return this._date;
    }

    @Input({required: true})
    set date(value: string) {
        this._date = value;
    }

    get format(): string {
        return this._format;
    }

    @Input()
    set format(value: string) {
        this._format = value;
    }
}
