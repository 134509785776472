import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import Actualite from '@models/actualites/actualite/actualite.model';
import {
    IActualiteActions, IActualiteDropdownData, IActualiteDropdownDataOptions
} from '@features/actualites/actualites.interfaces';

@Component({selector: 'actualite-dropdown', standalone: false, templateUrl: 'actualite.dropdown.component.html'})
export class ActualiteDropdownComponent implements IDynamicComponent {
    static readonly actions = {SEE: 'actualite_see'};
    static readonly initActualiteActions: IActualiteActions = {see: false};
    private _dropdownService = inject(DropdownService);
    private _options: IActualiteDropdownDataOptions = {...{enabledActions: ActualiteDropdownComponent.initActualiteActions}};
    private _actualite!: Actualite;

    @Input()
    set data(value: IActualiteDropdownData) {
        if (!value) {
            return;
        }

        this._actualite = value.actualite!;
        this._options = {...{enabledActions: ActualiteDropdownComponent.initActualiteActions}, ...value.options};
    }

    get options(): IActualiteDropdownDataOptions {
        return this._options;
    }

    see(): void {
        this._dropdownService.clicked(ActualiteDropdownComponent.actions.SEE, {actualite: this._actualite});
    }
}
