import {Component, inject, Input, output, ViewChild} from '@angular/core';
import Commune from '@models/communes/commune/commune.model';
import {map, take, tap} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';
import Quartier from '@models/quartiers/quartier/quartier.model';
import {CQuartiersService} from '@models/quartiers/collection/quartiers.collection.service';

// @todo À passer dans le répertoire commune
@Component({
    exportAs: 'communeQuartiersSelect',
    selector: 'app-commune-quartiers-select',
    standalone: false,
    templateUrl: 'commune.quartiers-select.component.html',
})
export class AppCommuneQuartiersSelectComponent {
    static readonly initCommuneQuartiersSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'nom',
        initWithEmptySearch: true,
        loadingText: 'Chargement des quartiers',
        name: 'communeQuartiersSelect',
        notFoundText: 'Aucun quartier trouvé',
        placeholder: 'Rechercher un quartier par son nom',
    };
    readonly selected = output<Quartier>();
    private _cQuartiersService = inject(CQuartiersService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppCommuneQuartiersSelectComponent.initCommuneQuartiersSelectOptions};
    private _commune!: Commune;
    private _quartier!: Quartier;
    private _quartiers: Quartier[] = [];

    @Input()
    set commune(value: Commune) {
        this._commune = value;
    }

    get quartier(): Quartier {
        return this._quartier;
    }

    @Input()
    set quartier(value: Quartier) {
        this._quartier = value;
    }

    get quartiers(): Quartier[] {
        return this._quartiers;
    }

    @ViewChild('communeQuartiersSelectInput')
    set communeVoiesSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppCommuneQuartiersSelectComponent.initCommuneQuartiersSelectOptions, ...value};
    }

    onSelect(quartier: unknown): void {
        this.selected.emit(quartier as Quartier);
    }

    search(search: string): void {
        this._quartiers = [];
        this._loading = true;
        this._cQuartiersService.searchFromCommune$(this._commune.uuid, search).pipe(
            map(cQuartiers => cQuartiers.results),
            tap(quartiers => this._quartiers = quartiers),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
