import {Component, inject, OnInit} from '@angular/core';
import {CActualitesFactory} from '@models/actualites/collection/actualites.collection.factory';
import {Observable, ReplaySubject} from 'rxjs';
import CActualites from '@models/actualites/collection/actualites.collection.model';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {DCActualiteViewComponent} from '@features/actualites/actualite/view/actualite-view.component';
import {ActualiteDropdownComponent} from '@features/actualites/actualite/dropdown/actualite.dropdown.component';
import Actualite from '@models/actualites/actualite/actualite.model';
import {take} from 'rxjs/operators';

@Component({selector: 'layout-actualites', standalone: false, templateUrl: 'layout-actualites.component.html'})
export class AppLayoutActualitesComponent implements OnInit {
    private _cActualitesFactory = inject(CActualitesFactory);
    private _modalService = inject(ModalService);
    private _cActualitesSource = new ReplaySubject<CActualites>(1);
    private _cActualites$ = this._cActualitesSource.asObservable();

    get cActualites$(): Observable<CActualites> {
        return this._cActualites$;
    }

    ngOnInit(): void {
        this.updateCActualites();
    }

    clickOnActualite(dropdownClicked: IDropdownClicked): void {
        if (dropdownClicked.action === ActualiteDropdownComponent.actions.SEE) {
            const {actualite} = dropdownClicked.value as {actualite: Actualite};

            this._modalService.open$(DCActualiteViewComponent, {link: actualite.linkSelf}).pipe().subscribe(_ => this.updateCActualites());
        }
    }

    updateCActualites(): void {
        this._cActualitesSource.next(undefined!);
        this._cActualitesFactory.get$().pipe(take(1)).subscribe(cActualites => this._cActualitesSource.next(cActualites));
    }
}
