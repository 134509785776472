import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {VenteMatchingFactory} from '@models/ventes/vente/matchings/matching/vente-matching.factory';
import {VenteMatchingsApiService} from '@models/ventes/vente/matchings/vente-matchings.api.service';
import {ICVenteMatchingsApi} from '@models/ventes/vente/matchings/collection/vente-matching.collection.interfaces';
import CVenteMatchings from '@models/ventes/vente/matchings/collection/vente-matching.collection.model';

@Injectable({providedIn: 'root'})
export class CVenteMatchingsFactory {
    private _venteMatchingFactory = inject(VenteMatchingFactory);
    private _venteMatchingsApiService = inject(VenteMatchingsApiService);

    create(cVenteMatchingsApi: ICVenteMatchingsApi): CVenteMatchings {
        const cVenteMatchings = new CVenteMatchings();

        cVenteMatchings.links = new Links(cVenteMatchingsApi._links);
        cVenteMatchings.page = cVenteMatchingsApi.page;
        cVenteMatchings.pages = cVenteMatchingsApi.pages;
        cVenteMatchings.perPage = cVenteMatchingsApi.limit;
        cVenteMatchings.total = cVenteMatchingsApi.total;

        if (cVenteMatchingsApi._embedded) {
            cVenteMatchings.results = (cVenteMatchingsApi._embedded.items || []).filter(venteMatchingApi => venteMatchingApi)
                .map(venteMatchingApi => this._venteMatchingFactory.create(venteMatchingApi));
        }

        return cVenteMatchings;
    }

    get$(venteUuid: string): Observable<CVenteMatchings> {
        return this._venteMatchingsApiService.getCollection$(venteUuid).pipe(map(cVenteMatchingsApi => this.create(cVenteMatchingsApi)));
    }
}
