import {Component, inject, Input} from '@angular/core';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import {Observable} from 'rxjs';
import CUsers from '@models/users/collection/users.collection.model';

@Component({
    selector: 'app-users-stacked-images',
    standalone: false,
    templateUrl: 'users.stacked-images.component.html',
})
export class AppUsersStackedImagesComponent {
    private _cUsersFactory = inject(CUsersFactory);
    private _cUsers$!: Observable<CUsers>;

    get cUsers$(): Observable<CUsers> {
        return this._cUsers$;
    }

    @Input()
    set linkUsers(value: string) {
        this._cUsers$ = this._cUsersFactory.getByLink$(value);
    }
}
