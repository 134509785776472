import {inject, NgModule} from '@angular/core';
import {
    NgEstimationEvaluationReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-references-criteria.component';
import {NgEstimationLifetimeComponent} from '@shared/angularJS/up-ng/estimations/estimations-item-lifetime.component';
import {
    NgEstimationsReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-references-criteria.component';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {AppLayoutEstimationsComponent} from '@core/routing/layout/estimations/layout-estimations.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AppLayoutEstimationComponent} from '@core/routing/layout/estimations/estimation/layout-estimation.component';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {
    AppLayoutEstimationEditComponent
} from '@core/routing/layout/estimations/estimation/edit/layout-estimation-edit.component';
import {CanDeactivateGuard} from '@core/routing/guards/can-deactivate/can-deactivate-guard.service';
import {
    AppLayoutEstimationEditGeneralComponent
} from '@core/routing/layout/estimations/estimation/edit/general/layout-estimation-edit-general.component';
import {FeaturesModule} from '@features/features.module';
import {
    AppLayoutBienEditConstructionComponent
} from '@core/routing/layout/biens/bien/edit/construction/layout-bien-edit-construction.component';
import {
    AppLayoutBienEditFormalitesComponent
} from '@core/routing/layout/biens/bien/edit/formalites/layout-bien-edit-formalites.component';
import {
    AppLayoutEstimationEvaluationComponent
} from '@core/routing/layout/estimations/estimation/evaluation/layout-estimation-evaluation.component';
import {AppLayoutDossierSuiviComponent} from '@core/routing/layout/suivi/layout-dossier-suivi.component';
import {
    NgEskimmoDossierSuiviEmailComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {
    NgEskimmoDossierSuiviReportComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {
    AppLayoutEstimationsReferencesComponent
} from '@core/routing/layout/estimations/references/layout-estimations-references.component';
import {
    NgEstimationsReferencesComponent
} from '@shared/angularJS/up-ng/estimations/estimations-references.component';
import {
    AppLayoutDossierFilesComponent
} from '@core/routing/layout/dossiers/dossier/files/layout-dossier-files.component';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {
    AppLayoutEstimationRapportComponent
} from '@core/routing/layout/estimations/estimation/rapport/layout-estimation.rapport.component';
import {
    AppLayoutEstimationRapportEditComponent
} from '@core/routing/layout/estimations/estimation/rapport/edit/layout-estimation.rapport-edit.component';
import {
    AppLayoutEstimationRapportWriteComponent
} from '@core/routing/layout/estimations/estimation/rapport/write/layout-estimation.rapport-write.component';
import {
    AppLayoutEstimationsPublicitesComponent
} from '@core/routing/layout/estimations/publicites/layout-estimations.publicites.component';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {GuardsService} from '@core/routing/guards/guards.service';
import {CanDeactivateComponent} from '@core/routing/guards/guards.interfaces';
import {NgBienDetailsComponent} from '@shared/angularJS/up-ng/biens/biens-item-details.component';
import {NgBiensWalletCurrentComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-current.component';
import {NgBienEditPhotosComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-photos.component';
import {NgBienEditVirtualVisitComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-virtual_visit.component';
import {
    AppLayoutEstimationEvaluationReferencesListComponent
} from '@core/routing/layout/estimations/estimation/evaluation/references/list/layout-estimation-evaluation-references-list.component';
import {EstimationActionsMainComponent} from '@features/estimations/estimation/actions/estimation.actions-main.component';
import {
    EstimationActionsSelectionComponent
} from '@features/estimations/estimation/actions/estimation.actions-selection.component';
import {
    NgEstimationEvaluationOnsaleReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-onsale-references-criteria.component';
import {
    AppLayoutEstimationEvaluationOnsaleReferencesListComponent
} from '@core/routing/layout/estimations/estimation/evaluation/onsale-references/list/layout-estimation-evaluation-onsale-references-list.component';
import {IsEnablableModulesGuard} from '@core/routing/guards/is-enablable-modules/is-enablable-modules-guard.service';
import {
    AppLayoutDossierInotExportComponent
} from '@core/routing/layout/dossiers/dossier/inot/export/layout-dossier-inot-export.component';
import {
    DossierInotExportActionsMainComponent
} from '@features/dossiers/dossier/inot/exports/export/actions/dossier-inot-export.actions-main.component';
import Etude from '@models/etudes/etude/etude.model';
import {ActualitesService} from '@models/actualites/actualites.service';
import {
    AppLayoutEstimationEvaluationRootComponent
} from '@core/routing/layout/estimations/estimation/evaluation/root/layout-estimation-evaluation-root.component';
import {
    AppLayoutEstimationRapportReadComponent
} from '@core/routing/layout/estimations/estimation/rapport/read/layout-estimation.rapport-read.component';
import { EstimationRapportService } from '@features/estimations/estimation/rapport/estimation-rapport.service';
import {DossierAccessGuardService} from '@features/dossiers/dossier/new-guard/dossier.access-guard.service';

const routes: Routes = [
    {component: AppLayoutEstimationsPublicitesComponent, path: 'informations'},
    {
        component: AppLayoutEstimationsComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {
            [GuardsService.REDIRECTION_ROUTE_DATA]: '/app/estimations/informations',
            roles: [User.roles.GRP_ESTIMATION],
        },
        path: '',
        children: [
            {component: NgBienDetailsComponent, path: 'details/:estimationId'},
            {
                component: NgBiensWalletCurrentComponent,
                path: 'portefeuille',
                resolve: {news: () => inject(ActualitesService).show$()},
            },
            {
                component: AppLayoutEstimationsReferencesComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
                ],
                data: {
                    [GuardsService.REDIRECTION_ROUTE_DATA]: '/app/estimations/portefeuille',
                    roles: [User.roles.GRP_VENTE],
                },
                path: 'references',
                children: [
                    {component: NgEstimationsReferencesCriteriaComponent, path: 'criteres'},
                    {component: NgBienDetailsComponent, path: 'details/:estimationReferenceId'},
                    {component: NgEstimationsReferencesComponent, path: ''},
                    // Wildcard pour /app/estimations/references/*
                    {path: '**', pathMatch: 'full', redirectTo: ''},
                ],
            },
            {
                component: AppLayoutEstimationComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) =>
                        inject(DossierAccessGuardService).canActivate(activatedRouteSnapshot, routerStateSnapshot),
                ],
                path: ':estimationId',
                children: [
                    {
                        component: AppLayoutDossierFilesComponent,
                        data: {
                            actions: {
                                main: {component: DossierFilesActionsMainComponent},
                                selection: {component: DossierFilesActionsSelectionComponent},
                            },
                        },
                        path: 'documents',
                    },
                    {
                        canDeactivate: [
                            (component: CanDeactivateComponent) => inject(CanDeactivateGuard).canDeactivate(component),
                        ],
                        component: AppLayoutEstimationEditComponent,
                        path: 'edit',
                        children: [
                            {component: AppLayoutBienEditConstructionComponent, path: 'construction'},
                            {component: AppLayoutBienEditFormalitesComponent, path: 'formalites'},
                            {component: AppLayoutEstimationEditGeneralComponent, path: 'general'},
                            {component: NgBienEditPhotosComponent, path: 'photos'},
                            {component: NgBienEditVirtualVisitComponent, path: 'virtual_visit'},
                            // Wildcard pour /app/estimations/:estimationId/edit/*
                            {path: '**', pathMatch: 'full', redirectTo: 'general'},
                        ],
                    },
                    {
                        component: AppLayoutEstimationEvaluationComponent,
                        path: 'evaluation',
                        children: [
                            {
                                path: 'onsale-references',
                                children: [
                                    {
                                        component: NgEstimationEvaluationOnsaleReferencesCriteriaComponent,
                                        path: 'criteres',
                                    },
                                    {
                                        component: AppLayoutEstimationEvaluationOnsaleReferencesListComponent,
                                        data: {
                                            actions: {
                                                main: {component: EstimationActionsMainComponent},
                                                selection: {component: EstimationActionsSelectionComponent},
                                            },
                                        },
                                        path: '',
                                    },
                                    // Wildcard pour /app/estimations/:estimationId/evaluation/onsale-references/*
                                    {path: '**', pathMatch: 'full', redirectTo: ''},
                                ],
                            },
                            {
                                path: 'references',
                                children: [
                                    {component: NgEstimationEvaluationReferencesCriteriaComponent, path: 'criteres'},
                                    {
                                        component: AppLayoutEstimationEvaluationReferencesListComponent,
                                        data: {
                                            actions: {
                                                main: {component: EstimationActionsMainComponent},
                                                selection: {component: EstimationActionsSelectionComponent},
                                            },
                                        },
                                        path: '',
                                    },
                                    // Wildcard pour /app/estimations/:estimationId/evaluation/references/*
                                    {path: '**', pathMatch: 'full', redirectTo: ''},
                                ],
                            },
                            {component: AppLayoutEstimationEvaluationRootComponent, path: ''},
                            // Wildcard pour /app/estimations/:estimationId/evaluation/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    {
                        canActivate: [
                            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(IsEnablableModulesGuard).canActivate(activatedRouteSnapshot),
                        ],
                        component: AppLayoutDossierInotExportComponent,
                        data: {
                            actions: {main: {component: DossierInotExportActionsMainComponent}},
                            enablableModules: [Etude.enablableModules.INOT.code],
                        },
                        path: 'inot/export',
                    },
                    {component: NgEstimationLifetimeComponent, path: 'lifetime'},
                    {
                        component: AppLayoutEstimationRapportComponent,
                        path: 'rapport',
                        children: [
                            {
                                component: AppLayoutEstimationRapportEditComponent,
                                path: EstimationRapportService.ROUTING.EDIT,
                            },
                            {
                                component: AppLayoutEstimationRapportReadComponent,
                                path: EstimationRapportService.ROUTING.READ,
                            },
                            {
                                component: AppLayoutEstimationRapportWriteComponent,
                                path: EstimationRapportService.ROUTING.WRITE,
                            },
                            // Wildcard pour /app/estimations/:estimationId/rapport
                            {path: '**', pathMatch: 'full', redirectTo: EstimationRapportService.ROUTING.READ},
                        ],
                    },
                    {
                        component: AppLayoutDossierSuiviComponent,
                        path: 'suivi',
                        children: [
                            {component: NgEskimmoDossierSuiviEmailComponent, path: 'emails/:emailId'},
                            {component: NgEskimmoDossierSuiviReportComponent, path: 'reports/:reportId'},
                            // Wildcard pour /app/estimations/:estimationId/suivi/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    // Wildcard pour /app/estimations/:estimationId/*
                    {path: '**', pathMatch: 'full', redirectTo: 'edit/general'},
                ],
            },
            // Wildcard pour /app/estimations/*
            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
        ],
    },
];

@NgModule({
    declarations: [
        AppLayoutEstimationComponent,
        AppLayoutEstimationEditComponent,
        AppLayoutEstimationEditGeneralComponent,
        AppLayoutEstimationEvaluationComponent,
        AppLayoutEstimationEvaluationOnsaleReferencesListComponent,
        AppLayoutEstimationEvaluationReferencesListComponent,
        AppLayoutEstimationRapportComponent,
        AppLayoutEstimationRapportEditComponent,
        AppLayoutEstimationRapportWriteComponent,
        AppLayoutEstimationsComponent,
        AppLayoutEstimationsPublicitesComponent,
        AppLayoutEstimationsReferencesComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class EstimationsRoutingModule {
}
