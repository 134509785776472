import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import CSites from '@models/sites/collection/sites.collection.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import Site from '@models/sites/site/site.model';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import CUsers from '@models/users/collection/users.collection.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {ConditionConst} from '@shared/constants';
import Vente from '@models/ventes/vente/vente.model';

@Component({
    selector: 'layout-annuaire-etude',
    standalone: false,
    templateUrl: 'layout-annuaire-etude.component.html'
})
export class AppLayoutAnnuaireEtudeComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _cSitesService = inject(CSitesService);
    private _cUsersFactory = inject(CUsersFactory);
    private _cVentesFactory = inject(CVentesFactory);
    private _etudeFactory = inject(EtudeFactory);
    private _etudeService = inject(EtudeService);
    private _router = inject(Router);
    private _cSitesAnnexes$!: Observable<CSites>;
    private _cUsers$!: Observable<CUsers>;
    private _cVentes$!: Observable<CVentes>;
    private _cVentesCompromis$!: Observable<CVentes>;
    private _etude$!: Observable<Etude>;
    private _sitePrincipal$!: Observable<Site>;
    private readonly _onDestroy$ = new Subject<void>();

    get cSitesAnnexes$(): Observable<CSites> {
        return this._cSitesAnnexes$;
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.current$;
    }

    get cUsers$(): Observable<CUsers> {
        return this._cUsers$;
    }

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    get cVentesCompromis$(): Observable<CVentes> {
        return this._cVentesCompromis$;
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get sitePrincipal$(): Observable<Site> {
        return this._sitePrincipal$;
    }

    get VENTE_STATUT_COMPROMIS(): string {
        return Vente.statuts.COMPROMIS;
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(
            map(params => (params as { etudeUuid: string }).etudeUuid),
            takeUntil(this._onDestroy$),
        ).subscribe(etudeUuid => {
            this._etude$ = this._etudeFactory.get$(etudeUuid);
            this._cVentes$ = this._etude$.pipe(
                switchMap(etude => this._cVentesFactory.getByLink$(etude.linkVentes)),
            );
            this._cVentesCompromis$ = this._etude$.pipe(
                switchMap(etude => this._cVentesFactory.get$({
                    etude: {condition: ConditionConst.IN, values: [etude.id]},
                    statut: {condition: ConditionConst.IN, values: [Vente.statuts.COMPROMIS]},
                })),
            );
            this._cSitesAnnexes$ = this._etude$.pipe(
                switchMap(etude => this._cSitesService.getCAnnexes$(etude.linkSites)),
            );
            this._cUsers$ = this._etude$.pipe(
                switchMap(etude => this._cUsersFactory.getByLink$(etude.linkUsers)),
            );
            this._sitePrincipal$ = this._etude$.pipe(
                switchMap(etude => this._cSitesService.getSitePrincipal$(etude.linkSites)),
            );
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    goToConfigurationEtude(): void {
        this._router.navigateByUrl('/app/configuration/etude');
    }
}
