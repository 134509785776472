import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import {NgBienCharge, NgBienDiagnostic, NgBienDossier} from '@legacy/app/managers/ressources';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {BienEditVisibilityPanelService} from '@features/bien-edit/shared/bien-edit.visibility-panel.service';
import DateFormat from '@shared/date/date.format';
import {IFormSelectDateOptions} from '@shared/form/form.interfaces';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {
    BienPerformanceEnergetiqueService
} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgForm} from '@angular/forms';
import FormService from '@shared/form/form.service';
import {NgBienChargeManager, NgBienDiagnosticManager, NgDictionariesManager} from '@legacy/app/managers/managers';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCBienEditDiagnosticComponent
} from '@features/bien-edit/dynamic-components/diagnostic/bien.edit-diagnostic.component';
import {DCBienEditChargeComponent} from '@features/bien-edit/dynamic-components/charge/bien.edit-charge.component';
import {RoutesService} from '@shared/routes/routes.service';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {CollectionSortableService} from '@shared/collection/sortable/collection.sortable.service';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

interface IOptionsFormalites {
    diagnosticDate: (ngBienDiagnostic: NgBienDiagnostic) => IFormSelectDateOptions;
    performanceEnergetiqueDate: IFormSelectDateOptions;
}

@Component({
    selector: 'layout-bien-edit-formalites',
    standalone: false,
    templateUrl: 'layout-bien-edit-formalites.component.html',
})
export class AppLayoutBienEditFormalitesComponent implements OnDestroy, OnInit {
    readonly AppLayoutBienEditFormalitesChargesName = 'AppLayoutBienEditFormalitesCharges';
    readonly AppLayoutBienEditFormalitesDiagnosticsName = 'AppLayoutBienEditFormalitesDiagnostics';
    private _bienEditVisibilityPanelService = inject(BienEditVisibilityPanelService);
    private _bienPerformanceEnergetiqueService = inject(BienPerformanceEnergetiqueService);
    private _collectionSortableService = inject(CollectionSortableService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienService = inject(DossierBienService);
    private _modalService = inject(ModalService);
    private _ngForm = inject(NgForm);
    private _ngInjectorService = inject(NgInjectorService);
    private _routesService = inject(RoutesService);
    private _bienOccupation!: DictionaryItem;
    private _bienPerformanceEnergetiqueAnneeReference!: number;
    private _bienResidence!: DictionaryItem;
    private _bienUsage!: DictionaryItem;
    private _chargesReadySource = new BehaviorSubject(true);
    private _chargesReady$ = this._chargesReadySource.asObservable();
    private _diagnosticsReadySource = new BehaviorSubject(true);
    private _diagnosticsReady$ = this._diagnosticsReadySource.asObservable();
    private _dossierBien!: ADossierBien;
    private _isDiagV2 = false;
    private _ngBienDossier!: NgBienDossier;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IOptionsFormalites = {
        diagnosticDate: (ngBienDiagnostic: NgBienDiagnostic) => ({
            inputClass: 'form-control',
            max: DateFormat.toISOString(),
            name: 'diagnosticDate' + ngBienDiagnostic.uuid,
        }),
        performanceEnergetiqueDate: {
            inputClass: 'form-control',
            max: DateFormat.toISOString(),
            min: BienPerformanceEnergetique.DATE_MIN,
            name: 'performanceEnergetiqueDate',
        },
    };
    private _superficieLabel = DictionaryItemService.superficieLabel.DEFAULT;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngBienChargeManager(): NgBienChargeManager {
        return this._ngInjectorService.getService('BienChargeManager');
    }

    // Supprimer les injections des anciens manager
    private get ngBienDiagnosticManager(): NgBienDiagnosticManager {
        return this._ngInjectorService.getService('BienDiagnosticManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    get bienOccupation(): DictionaryItem {
        return this._bienOccupation;
    }

    get bienPerformanceEnergetiqueAnneeReference(): number {
        return this._bienPerformanceEnergetiqueAnneeReference;
    }

    set bienPerformanceEnergetiqueAnneeReference(value: number) {
        this._bienPerformanceEnergetiqueAnneeReference = value;
    }

    get bienResidence(): DictionaryItem {
        return this._bienResidence;
    }

    get bienUsage(): DictionaryItem {
        return this._bienUsage;
    }

    get chargesReady$(): Observable<boolean> {
        return this._chargesReady$;
    }

    get diagnosticsReady$(): Observable<boolean> {
        return this._diagnosticsReady$;
    }

    get DICTIONARY_NAME_CHARGES(): string {
        return Dictionary.names.CHARGES;
    }

    get DICTIONARY_NAME_DIAGNOSTIC_STATUTS(): string {
        return Dictionary.names.DIAGNOSTIC_STATUTS;
    }

    get DICTIONARY_NAME_DIAGNOSTIC_TYPES(): string {
        return Dictionary.names.DIAGNOSTIC_TYPES;
    }

    get DICTIONARY_NAME_OCCUPATIONS(): string {
        return Dictionary.names.OCCUPATIONS;
    }

    get DICTIONARY_NAME_PERFORMANCE_ENERGETIQUE_CLASSES(): string {
        return Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES;
    }

    get DICTIONARY_NAME_PERIODICITES(): string {
        return Dictionary.names.PERIODICITES;
    }

    get DICTIONARY_NAME_RESIDENCES(): string {
        return Dictionary.names.RESIDENCES;
    }

    get DICTIONARY_NAME_USAGES(): string {
        return Dictionary.names.USAGES;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienFieldsConst;
    }

    get isDiagV2(): boolean {
        return this._isDiagV2;
    }

    get ngBienDossier(): NgBienDossier {
        return this._ngBienDossier;
    }

    get options(): IOptionsFormalites {
        return this._options;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._dossierBienService.getNgCurrent$(this._routesService.getTypeDossierFromUrl()).pipe(
            tap(ngBienDossier => {
                if (ngBienDossier.bien.option.occupation) {
                    this._bienOccupation = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.occupation);
                } else {
                    this._bienOccupation = undefined!;
                }

                if (ngBienDossier.bien.option.residence) {
                    this._bienResidence = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.residence);
                } else {
                    this._bienResidence = undefined!;
                }

                if (ngBienDossier.bien.option.usage) {
                    this._bienUsage = this._dictionaryItemFactory.ngCreate(ngBienDossier.bien.option.usage);
                } else {
                    this._bienUsage = undefined!;
                }
            }),
            tap(ngBienDossier => this._dossierBien = this._dossierBienFactory.ngCreate(ngBienDossier)),
            tap(_ => this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._dossierBien.bien.nature)),
            tap(_ => {
                if (this._dossierBien.bien.performanceEnergetique.dateReference) {
                    this._bienPerformanceEnergetiqueAnneeReference = DateFormat.getYear(this._dossierBien.bien.performanceEnergetique.dateReference);
                }
            }),
            tap(_ => this._isDiagV2 = this._bienPerformanceEnergetiqueService.isDiagV2(this._dossierBien.bien)),
            takeUntil(this._onDestroy$),
        ).subscribe(ngBienDossier => this._ngBienDossier = ngBienDossier);
        this.chargesReady$.pipe(
            filter(isReady => isReady),
            switchMap(() => this._collectionSortableService.isInit$(this.AppLayoutBienEditFormalitesChargesName)),
            filter(isInit => isInit),
            switchMap(() => this._collectionSortableService.getMovedItem$(this.AppLayoutBienEditFormalitesChargesName)),
            map(({item, idx}) => ({ngBienCharge: item, idx} as { ngBienCharge: NgBienCharge, idx: number})),
            takeUntil(this._onDestroy$),
        ).subscribe(({ngBienCharge, idx}) => ngBienCharge.updateRank(idx + 1));
        this.diagnosticsReady$.pipe(
            filter(isReady => isReady),
            switchMap(() => this._collectionSortableService.isInit$(this.AppLayoutBienEditFormalitesDiagnosticsName)),
            filter(isInit => isInit),
            switchMap(() => this._collectionSortableService.getMovedItem$(this.AppLayoutBienEditFormalitesDiagnosticsName)),
            map(({item, idx}) => ({ngBienDiagnostic: item, idx} as { ngBienDiagnostic: NgBienDiagnostic, idx: number})),
            takeUntil(this._onDestroy$),
        ).subscribe(({ngBienDiagnostic, idx}) => ngBienDiagnostic.updateRank(idx + 1));
    }

    ngOnDestroy(): void {
        this._collectionSortableService.delete(this.AppLayoutBienEditFormalitesChargesName);
        this._collectionSortableService.delete(this.AppLayoutBienEditFormalitesDiagnosticsName);
        this._onDestroy$.next();
    }

    addNewCharge(): void {
        const virginCharge = this.ngBienChargeManager.create({}, this.ngBienDossier.bien.id);

        this.editCharge(virginCharge);
    }

    addNewDiagnostic(): void {
        const virginDiagnostic = this.ngBienDiagnosticManager.create({}, this.ngBienDossier.bien.id);

        this.editDiagnostic(virginDiagnostic);
    }

    editCharge(ngBienChargeToSave: NgBienCharge): void {
        this._modalService.open$(DCBienEditChargeComponent, {
            ngBienDossier: this.ngBienDossier,
            ngBienCharge: ngBienChargeToSave.copy(),
        }).pipe(
            map(ngBienChargeSaved => ngBienChargeSaved as NgBienCharge),
            tap(() => this._chargesReadySource.next(false)),
            take(1),
        ).subscribe({
            next: ngBienChargeSaved => {
                if (!ngBienChargeSaved) {
                    return;
                }

                const idx = this.ngBienDossier.bien.charges.findIndex(ngBienChargeFinding => ngBienChargeFinding.uuid === ngBienChargeSaved.uuid);

                if (idx >= 0) {
                    this.ngBienDossier.bien.charges[idx] = ngBienChargeSaved;
                } else {
                    this.ngBienDossier.bien.charges.push(ngBienChargeSaved);
                }
            },
            complete: () => this._chargesReadySource.next(true),
        });
    }

    editDiagnostic(ngBienDiagnosticToSave: NgBienDiagnostic): void {
        this._modalService.open$(DCBienEditDiagnosticComponent, {
            ngBienDossier: this.ngBienDossier,
            ngBienDiagnostic: ngBienDiagnosticToSave.copy(),
        }).pipe(
            map(ngBienDiagnosticSaved => ngBienDiagnosticSaved as NgBienDiagnostic),
            tap(() => this._diagnosticsReadySource.next(false)),
            take(1),
        ).subscribe({
            next: ngBienDiagnosticSaved => {
                if (!ngBienDiagnosticSaved) {
                    return;
                }

                const idx = this.ngBienDossier.bien.diagnostics.findIndex(ngBienDiagnosticFinding => ngBienDiagnosticFinding.uuid === ngBienDiagnosticSaved.uuid);

                if (idx >= 0) {
                    this.ngBienDossier.bien.diagnostics[idx] = ngBienDiagnosticSaved;
                } else {
                    this.ngBienDossier.bien.diagnostics.push(ngBienDiagnosticSaved);
                }
            },
            complete: () => this._diagnosticsReadySource.next(true),
        });
    }

    hasConstraints(constraints: string[]): boolean {
        return constraints.some(constraint => this.ngBienDossierCurrent.hasConstraints(constraint));
    }

    hasErrorOnConstraints(constraints: string[]): boolean {
        return FormService.hasErrorOnConstraints(this._ngForm, constraints);
    }

    onSelectChargePeriodicite(ngBienCharge: NgBienCharge, periodicite: DictionarySelected): void {
        if (periodicite && !Array.isArray(periodicite)) {
            ngBienCharge.periodicite = this.ngDictionariesManager.createFromNg2(periodicite);
        } else {
            ngBienCharge.periodicite = undefined!;
        }

        this.saveCharge(ngBienCharge);
    }

    onSelectChargeType(ngBienCharge: NgBienCharge, chargeType: DictionarySelected): void {
        if (chargeType && !Array.isArray(chargeType)) {
            ngBienCharge.charge = this.ngDictionariesManager.createFromNg2(chargeType);
        } else {
            ngBienCharge.charge = undefined!;
        }

        this.saveCharge(ngBienCharge);
    }

    onSelectDateDisponibilite(date: string): void {
        this._ngBienDossier.bien.metadata.occupation.dateDisponibilite = DateFormat.toAPI(date) as unknown as Date;
    }

    onSelectDiagnosticDate(ngBienDiagnostic: NgBienDiagnostic, date: string): void {
        ngBienDiagnostic.date = DateFormat.toDate(date);
        this.saveDiagnostic(ngBienDiagnostic);
    }

    onSelectDiagnosticType(ngBienDiagnostic: NgBienDiagnostic, diagnosticType: DictionarySelected): void {
        if (diagnosticType && !Array.isArray(diagnosticType)) {
            ngBienDiagnostic.typeDiagnostic = this.ngDictionariesManager.createFromNg2(diagnosticType);
        } else {
            ngBienDiagnostic.typeDiagnostic = undefined!;
        }

        this.saveDiagnostic(ngBienDiagnostic);
    }

    onSelectOccupation(occupationSelected: DictionarySelected): void {
        this._bienOccupation = occupationSelected as DictionaryItem;
        this._ngBienDossier.bien.option.occupation = this.ngDictionariesManager.createFromNg2(this._bienOccupation);
    }

    onSelectPerformanceEnergetiqueConsommationClasse(consommationClasse: DictionarySelected): void {
        this._dossierBien.bien.performanceEnergetique.consommationClasse = consommationClasse as DictionaryItem;
        this.updateBienPerformanceEnergetique();
    }

    onSelectPerformanceEnergetiqueDate(date: string): void {
        this._dossierBien.bien.performanceEnergetique.date = DateFormat.toAPI(date);
        this.updateBienPerformanceEnergetique();
    }

    onSelectPerformanceEnergetiqueEmissionsClasse(emissionsClasse: DictionarySelected): void {
        this._dossierBien.bien.performanceEnergetique.emissionsClasse = emissionsClasse as DictionaryItem;
        this.updateBienPerformanceEnergetique();
    }

    onSelectPerformanceEnergetiqueStatutDpe(statutDpe: DictionarySelected): void {
        this._dossierBien.bien.performanceEnergetique.statutDpe = statutDpe as DictionaryItem;
        this.updateBienPerformanceEnergetique();
    }

    onSelectResidence(residenceSelected: DictionarySelected): void {
        this._bienResidence = residenceSelected as DictionaryItem;
        this._ngBienDossier.bien.option.residence = this.ngDictionariesManager.createFromNg2(this._bienResidence);
    }

    onSelectUsage(usageSelected: DictionarySelected): void {
        this._bienUsage = usageSelected as DictionaryItem;
        this._ngBienDossier.bien.option.usage = this.ngDictionariesManager.createFromNg2(this._bienUsage);
    }

    removeCharge(ngBienCharge: NgBienCharge): void {
        from(this.ngBienDossier.bien.removeItem('charges', ngBienCharge)).pipe(take(1)).subscribe();
    }

    removeDiagnostic(ngBienDiagnostic: NgBienDiagnostic): void {
        from(this.ngBienDossier.bien.removeItem('diagnostics', ngBienDiagnostic)).pipe(take(1)).subscribe();
    }

    saveDiagnostic(ngBienDiagnostic: NgBienDiagnostic): void {
        this._ngBienDossier.bien.saveItem('diagnostics', ngBienDiagnostic, true);
    }

    saveCharge(ngBienCharge: NgBienCharge): void {
        this._ngBienDossier.bien.saveItem('charges', ngBienCharge, true);
    }

    showFieldForBien(field: string): boolean {
        return this._dossierBienFieldsService.isEditionVisible(this._dossierBien, field);
    }

    showPanelForBien(panel: string): boolean {
        return this._bienEditVisibilityPanelService.isVisible(this._dossierBien, panel);
    }

    updateBienPerformanceEnergetique(): void {
        setTimeout(() => {
            this._dossierBien.bien.performanceEnergetique.consommation = FormService.parseNumber(this._dossierBien.bien.performanceEnergetique.consommation);
            this._dossierBien.bien.performanceEnergetique.coutMax = FormService.parseNumber(this._dossierBien.bien.performanceEnergetique.coutMax);
            this._dossierBien.bien.performanceEnergetique.coutMin = FormService.parseNumber(this._dossierBien.bien.performanceEnergetique.coutMin);
            this._dossierBien.bien.performanceEnergetique.emissions = FormService.parseNumber(this._dossierBien.bien.performanceEnergetique.emissions);

            if (this._ngBienDossier.bien.performanceEnergetique.consommation?.toString() !== this._dossierBien.bien.performanceEnergetique.consommation?.toString()) {
                const performanceEnergetiqueConsommationClasse = this._bienPerformanceEnergetiqueService.getConsommationClasse(this._dossierBien.bien);

                this._dossierBien.bien.performanceEnergetique.consommationClasse =
                    this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, performanceEnergetiqueConsommationClasse);
            }

            if (this._ngBienDossier.bien.performanceEnergetique.emissions?.toString() !== this._dossierBien.bien.performanceEnergetique.emissions?.toString()) {
                const performanceEnergetiqueEmissionsClasse = this._bienPerformanceEnergetiqueService.getEmissionsClasse(this._dossierBien.bien);

                this._dossierBien.bien.performanceEnergetique.emissionsClasse =
                    this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, performanceEnergetiqueEmissionsClasse);
            }

            if (this._ngBienDossier.bien.performanceEnergetique.emissionsClasse !== this._dossierBien.bien.performanceEnergetique.emissionsClasse?.code) {
                const performanceEnergetiqueConsommationClasse = this._bienPerformanceEnergetiqueService.getConsommationClasse(this._dossierBien.bien);

                this._dossierBien.bien.performanceEnergetique.consommationClasse =
                    this._dictionaryItemService.getByCode(Dictionary.names.PERFORMANCE_ENERGETIQUE_CLASSES, performanceEnergetiqueConsommationClasse);
            }

            this._isDiagV2 = this._bienPerformanceEnergetiqueService.isDiagV2(this._dossierBien.bien);
            this._ngBienDossier.bien.performanceEnergetique.comments = this._dossierBien.bien.performanceEnergetique.comments;
            this._ngBienDossier.bien.performanceEnergetique.consommation = this._dossierBien.bien.performanceEnergetique.consommation?.toString() as unknown as number;
            this._ngBienDossier.bien.performanceEnergetique.consommationClasse = this._dossierBien.bien.performanceEnergetique.consommationClasse?.code as unknown as string;
            this._ngBienDossier.bien.performanceEnergetique.coutMax = this._dossierBien.bien.performanceEnergetique.coutMax?.toString() as unknown as number;
            this._ngBienDossier.bien.performanceEnergetique.coutMin = this._dossierBien.bien.performanceEnergetique.coutMin?.toString() as unknown as number;
            this._ngBienDossier.bien.performanceEnergetique.date = this._dossierBien.bien.performanceEnergetique.date;
            this._ngBienDossier.bien.performanceEnergetique.dateReference = this._dossierBien.bien.performanceEnergetique.dateReference;
            this._ngBienDossier.bien.performanceEnergetique.emissions = this._dossierBien.bien.performanceEnergetique.emissions?.toString() as unknown as number;
            this._ngBienDossier.bien.performanceEnergetique.emissionsClasse = this._dossierBien.bien.performanceEnergetique.emissionsClasse?.code as unknown as string;
            this._ngBienDossier.bien.performanceEnergetique.statutDpe = this._dossierBien.bien.performanceEnergetique.statutDpe?.code;
        }, 1);
    }

    updateBienPerformanceEnergetiqueAnneeReference(): void {
        const parseAnneReference = FormService.parseNumber(this._bienPerformanceEnergetiqueAnneeReference);

        if (parseAnneReference) {
            let dateReference = parseAnneReference.toString() + '-01-01';

            while (dateReference.length < 10) {
                dateReference = '0' + dateReference;
            }

            this._dossierBien.bien.performanceEnergetique.dateReference = DateFormat.toAPI(dateReference);
        } else {
            this._dossierBien.bien.performanceEnergetique.dateReference = undefined!;
        }

        this.updateBienPerformanceEnergetique();
    }
}
