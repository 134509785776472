import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewTerrainService
} from '@features/dossiers/dossier/bien/overview/terrain/dossier-bien-overview.terrain.service';

@Component({
    selector: 'app-dossier-bien-overview-terrain',
    standalone: false,
    templateUrl: 'dossier-bien-overview.terrain.component.html',
})
export class AppDossierBienOverviewTerrainComponent {
    private _dossierBienOverviewTerrainService = inject(DossierBienOverviewTerrainService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isCloturesVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isCloturesVisible;
    }

    get isCommentsTerrainVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isCommentsTerrainVisible;
    }

    get isDecliviteVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isDecliviteVisible;
    }

    get isLongueurFacadeVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isLongueurFacadeVisible;
    }

    get isPanneauxPhotovoltaiquesVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isPanneauxPhotovoltaiquesVisible;
    }

    get isPointEauCampagneVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isPointEauCampagneVisible;
    }

    get isPointEauVilleVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isPointEauVilleVisible;
    }

    get isServitudeVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isServitudeVisible;
    }

    get isSurfaceCadastraleVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isSurfaceCadastraleVisible;
    }

    get isSurfaceCarrezVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isSurfaceCarrezVisible;
    }

    get isSurfaceBatieVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isSurfaceBatieVisible;
    }

    get isSurfaceNonBatieVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isSurfaceNonBatieVisible;
    }

    get isSurfacePlancherVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isSurfacePlancherVisible;
    }

    get isZoneInondableVisible(): boolean {
        return this._dossierBienOverviewTerrainService.isZoneInondableVisible;
    }
}
